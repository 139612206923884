<template>
    <div class="cont">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>AIOT</el-breadcrumb-item>
             <el-breadcrumb-item>酒店设备</el-breadcrumb-item>
             <el-breadcrumb-item>场景配置</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
      <el-row class="content-box">
        <el-row class="search-box">
           <el-row class="search-row">
              <el-row class="search-item">
                  <label v-text="$t('msg.hotel_a')">酒店：</label>
                  <el-select class="width-220" v-model="hotelVal" @change="hotel_change" :placeholder="$t('msg.select')" >
                     <el-option v-for="item in hotelOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
              </el-row>
              <el-row class="search-item">
                  <label v-text="$t('msg.building_unit_a')">楼栋/单元：</label>
                  <el-cascader
                        v-model="buildingUnit"
                        :options="buildingList"
                        @change="buildingChange"
                        clearable
                        class="width-220">
                  </el-cascader>
              </el-row>
              <el-row class="search-item">
                 <label>场景模式：</label>
                 <el-input class="width-220"  v-model="scenes" :placeholder="$t('msg.scene_model')" clearable></el-input>
              </el-row>
           </el-row>
           <el-row class="search-row">
              <el-row class="search-item">
                 <label v-text="$t('msg.room_num_a')">房间号：</label>
                 <el-select class="width-220" v-model="roomVal" :placeholder="$t('msg.select')"  filterable clearable>
                    <el-option v-for="item in roomOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                 </el-select>
              </el-row>
              <el-button class="bg-gradient" type="primary" @click="handleSearch(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
              <el-button  type="primary" @click="handleReset()" plain><span v-text="$t('msg.reset')">重置</span></el-button>
           </el-row>
        </el-row>
        <el-row class="cascader-height">
        </el-row>
        <!-- 复制 -->
        <el-form label-width="105px">
            <el-dialog :title="$t('msg.copy_scene')" :visible.sync="dCopyRoom" width="750px" :before-close="handleClose">
                <div class="desc-box" style="margin: 15px 0px;">
                    <span style="color:#FF5722" v-text="$t('msg.special_note_a')">特别说明：</span>
                    <span v-text="$t('msg.copy_scene_explain_txts')">复制场景功能，会根据该房间的设备位置、设备类型进行查找，可能会匹配到零个及以上的设备。建议配置完成后，进入详情查看，不需要加入到场景的设备，可以在编辑功能里去掉该设备。</span>
                </div>
                <!-- <el-row>
          <span class="label" v-text="$t('msg.hotel_a')">酒店：</span>
          <el-select class="width-160" v-model="hotelValRoom" @change="room_change" :placeholder="$t('msg.select')"  clearable>
            <el-option v-for="item in hotelOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <span v-text="$t('msg.room_type_a')">房间类型：</span>
          <el-select class="width-90" v-model="dRoomTypeVal" :placeholder="$t('msg.select')"  clearable>
            <el-option v-for="item in dRoomTypeOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <span v-text="$t('msg.floor_a')">楼层：</span>
          <el-select class="width-90" v-model="dFloorVal" :placeholder="$t('msg.select')"  clearable>
            <el-option v-for="item in dFloorOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-button class="m-bottom-5"  type="primary" icon="el-icon-search" @click="search_room_type()"><span v-text="$t('msg.search')">搜索</span></el-button>
          <el-button class="m-bottom-5"  type="primary" icon="el-icon-refresh-right" @click="copy_reset()"><span v-text="$t('msg.reset')">重置</span></el-button>
        </el-row> -->
                <div class="m-bottom-30">
                    <el-checkbox v-model="copyAllSelect" @change="all_select_copy"><span v-text="$t('msg.all_select')">全选</span></el-checkbox>
                    <el-button style="float: right;"  type="primary" @click="copy_save()"><span v-text="$t('msg.clone')">克隆</span></el-button>
                    <el-button style="float: right;" class="m-right-30"  type="info" @click="copy_save(2)"><span v-text="$t('msg.fuzzy_copy')">模糊复制</span></el-button>
                </div>
                <div class="room-list-box">
                    <el-row class="m-bottom-15">
                        <el-col v-for="(item, idx) in dCopyRoomArr" :key="idx" :span="4" class="m-bottom-15">
                            <el-checkbox v-model="item.checked"><span v-text="item.roomNo"></span></el-checkbox>
                        </el-col>
                    </el-row>
                </div>
                <el-row v-if="copySave">
                    <div class="success-room-box">
                        <p class="copy-tit" v-text="$t('msg.copy_scene_success_room')">复制成功房间</p>
                        <div class="room-list">
                            <el-col v-for="(item, idx) in dCopySuccessRoom" :key="idx" :span="6" class="m-bottom-10" v-text="item.roomNo"></el-col>
                        </div>
                    </div>
                    <div class="failure-room-box">
                        <p class="copy-tit" v-text="$t('msg.copy_scene_failure_room')">复制失败房间</p>
                        <div class="room-list">
                            <el-col v-for="(item, idx) in dCopyFailureRoom" :key="idx" :span="24" class="m-bottom-10 room-list-info">
                                <span v-text="item.roomNo" class="box-pad"></span>
                                <span class="prompt_info"></span>
                                <span class="over_info" v-text="item.reason"></span>
                                <span v-if="item.reason.length > 20">
                  <el-tooltip content="" placement="top" effect="light">
                    <div slot="content" class="width-130" v-text="item.reason"></div>
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </span>
                            </el-col>
                        </div>
                    </div>
                </el-row>
                <span slot="footer" class="dialog-footer">
          <!-- <el-button type="primary" :loading="true">加载中</el-button> -->
                    <!-- <el-button  type="primary" :loading="copySave" @click="copy_save()"><span v-text="$t('msg.save')">保存</span></el-button> -->
          <el-button  type="primary" @click="dCopyRoom = false" v-text="$t('msg.copy_know_the')">我知道了</el-button>
        </span>
            </el-dialog>
        </el-form>
        <!-- 复制成功、失败 -->
        <!-- <el-form label-width="105px" class="copy_scene">
      <el-dialog :title="$t('msg.copy_scene_app_info')" :visible.sync="dCopyResult" width="800px" :before-close="handleClose">
        <el-row>
          <div class="success-room-box">
            <p class="copy-tit" v-text="$t('msg.copy_scene_success_room')">复制成功房间</p>
            <div class="room-list">
              <el-col v-for="(item, idx) in dCopySuccessRoom" :key="idx" :span="6" class="m-bottom-10" v-text="item.roomNo"></el-col>
            </div>
          </div>
          <div class="failure-room-box">
            <p class="copy-tit" v-text="$t('msg.copy_scene_failure_room')">复制失败房间</p>
            <div class="room-list">
              <el-col v-for="(item, idx) in dCopyFailureRoom" :key="idx" :span="24" class="m-bottom-10 room-list-info">
                <span v-text="item.roomNo"></span>
                <span class="prompt_info"></span>
                <span class="over_info" v-text="item.reason"></span>
                <span v-if="item.reason.length > 20">
                  <el-tooltip content="" placement="top" effect="light">
                    <div slot="content" class="width-130" v-text="item.reason"></div>
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </span>
              </el-col>
            </div>
          </div>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button  type="primary" @click="dCopyResult = false" v-text="$t('msg.copy_know_the')">我知道了</el-button>
        </span>
      </el-dialog>
    </el-form> -->
        <!-- 表格 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-button-group style="float:none;">
                  <el-button :class="statusVal === '1' ? 'bg-gradient': ''"  @click="statusVal = '1'"><span v-text="$t('msg.valid')">有效</span></el-button>
                  <el-button :class="statusVal === '0' ? 'bg-gradient': ''"  @click="statusVal = '0'"><span v-text="$t('msg.invalid')">无效</span></el-button>
               </el-button-group>
               <el-row class="right-btn">
<!--                  <el-button  type="primary" @click="copy_scene()" plain><span v-text="$t('msg.copy_scene')">复制场景</span></el-button>-->
<!--                  <el-button class="bg-gradient" type="primary" @click="scenes_edit()"><span v-text="$t('msg.add_scene')">新建场景</span></el-button>-->
               </el-row>
            </div>
            <el-row class="floor-bottom">
               <hotel-floor :floorOpt="floorOpt" @setFloorClick="getFloorClick"/>
            </el-row>
            <el-table border fit v-loading="loading" :data="tableData" highlight-current-row @selection-change="handleSelectionChange" style="width: 100%" min-height="780" max-height="780" :stripe="true"  >
               <el-table-column type="selection" width="55"></el-table-column>
               <el-table-column prop="number" :label="$t('msg.number')" width="70"></el-table-column>
               <el-table-column label="场景图片" min-width="90"></el-table-column>
               <el-table-column prop="scene_model" :label="$t('msg.scene_model')" min-width="110"></el-table-column>
               <el-table-column prop="own_hotel" :label="$t('msg.own_hotel')" min-width="150"></el-table-column>
               <el-table-column prop="floor_room_num" :label="$t('msg.floor_room')" min-width="110" sortable>
                    <template slot-scope="scope">
                        <span v-text="scope.row.floor_num"></span>
                        <span v-if="scope.row.floor_num !== '—'" v-text="$t('msg.floors')">层</span>
                        <span v-text="'/'"></span>
                        <span v-text="scope.row.room_num"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" :label="$t('msg.unlock_status')" min-width="130">
                    <template slot-scope="scope">
                        <span v-if="scope.row.lockedSwitch === 'LOCKED'" style="color: #67c23a;" v-text="$t('msg.are_unlocked')">已锁定</span>
                        <span v-else style="color: #F56C6C;" v-text="$t('msg.not_unlock')">未锁定</span>
                        <el-switch  style="margin: 0px 5px;" v-model="scope.row.lockedSwitch"
                                    active-color="#67c23a"
                                    inactive-color="#dcdfe6"
                                    active-value="LOCKED"
                                    inactive-value="UNLOCK"
                                    @change="scene_state_change(scope.row.scene_id,scope.row.lockedSwitch, 1)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="sync_status" :label="$t('msg.sync_status')" :min-width="syncWidth">
                    <template slot-scope="scope">
                        <span v-if="scope.row.syncStatus === 'UN_SYNC'" style="color: #9797A2;" v-text="$t('msg.no_sync')">未同步</span>
                        <span v-else-if="scope.row.syncStatus === 'ADD_REPLY'" style="color: #e6a23c;" v-text="$t('msg.add_gateway')">新增中 - 待网关回复</span>
                        <span v-else-if="scope.row.syncStatus === 'UPDATED_UN_SYNC'" style="color: #9797A2;" v-text="$t('msg.edit_sync')">已修改 - 未同步</span>
                        <span v-else-if="scope.row.syncStatus === 'UPDATE_REPLY'" style="color: #e6a23c;" v-text="$t('msg.edit_gateway')">修改中 - 待网关回复</span>
                        <span v-else-if="scope.row.syncStatus === 'DELETE_REPLY'" style="color: #e6a23c;" v-text="$t('msg.del_gateway')">删除中 - 待网关回复</span>
                        <span v-else-if="scope.row.syncStatus === 'SUCCESS'" style="color: #67c23a;" v-text="$t('msg.sync_success')">同步成功</span>
                        <span v-else-if="scope.row.syncStatus === 'FAILURE'" style="color: #F56C6C;" v-text="$t('msg.sync_failure')">同步失败</span>
                        <span v-else>{{promptIocn}}</span>
                    </template>
                </el-table-column>
               <el-table-column prop="status" :label="$t('msg.status')" min-width="110">
                  <template slot-scope="scope">
                     <span class="box-span mini bg-green" v-if="scope.row.stateSwitch === 'VALID'"></span>
                     <span class="box-span mini bg-gray" v-else></span>
                  </template>
               </el-table-column>
                <el-table-column prop="createTime" :label="$t('msg.create_time')" min-width="150" sortable></el-table-column>
                <el-table-column :label="$t('msg.operating')" min-width="220" fixed="right">
                    <template slot-scope="scope">
                        <el-button  type="text" icon="el-icon-warning-outline" @click="scenes_detail(scope.row)" >
                            <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
                        </el-button>
                        <el-button type="text" icon="el-icon-edit" @click="scenes_edit(scope.row)" >
                            <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                        </el-button>
                        <el-dropdown class="m-left-10">
                            <el-button type="text" >
                                <el-link type="primary" v-text="$t('msg.more')">更多</el-link><i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-button v-if="scope.row.stateSwitch === 'VALID'" type="text" icon="el-icon-error" @click="scene_state_change(scope.row.scene_id,'INVALID', 2)" >
                                        <el-link type="primary" v-text="$t('msg.disable')">禁用</el-link>
                                    </el-button>
                                    <el-button v-else type="text" icon="el-icon-success" @click="scene_state_change(scope.row.scene_id,'VALID', 2)" >
                                        <el-link type="primary" v-text="$t('msg.enable')">启用</el-link>
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="remove(scope.row)" >
                                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                                    </el-button>
                                </el-dropdown-item>
                                <!--<el-dropdown-item>
                                    <el-button type="text" icon="el-icon-copy-document" @click="copy(scope.row)" >
                                        <el-link type="primary" v-text="$t('msg.copy')">复制</el-link>
                                    </el-button>
                                </el-dropdown-item>-->
                                <el-dropdown-item>
                                    <el-button type="text" icon="el-icon-connection" @click="gateway(scope.row)" >
                                        <el-link type="primary" v-text="$t('msg.sync_gateway')">同步网关</el-link>
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-row class="pag_bottom">
                  <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
                  <div class="pag_button" style="margin-top: 20px">
                     <el-button   type="danger" class="m-bottom-5" @click="all_delete()"><span v-text="$t('msg.all_delete')">批量删除</span></el-button>
                     <el-button   type="info" class="m-bottom-5" @click="all_locking(false)"><span v-text="$t('msg.all_unlocking')">批量解锁</span></el-button>
                     <el-button   type="success" class="m-bottom-5" @click="all_locking(true)"><span v-text="$t('msg.all_locking')">批量锁定</span></el-button>
                  </div>
            </el-row>
         </div>
      </el-row>
   </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   import { mapState } from 'vuex'
   export default {
      computed:{
         ...mapState(['hotelInfo'])
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelValExample = this.hotelInfo.id
                  this.hotel_list()
               }
            }
         }
      },
      name: 'hotelScenesConfig',
      data () {
         return {
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            promptIocn: '—',
            add_success: '添加成功！',
            update_success: '修改成功！',
            refresh_success: '刷新成功！',
            sync_gateway_success: '指令发送成功，待网关确认！',
            confirm_edit: '确定修改？',
            confirm_remove: '确定移除？',
            confirm_lock: '确定锁定？',
            confirm_unlock: '确定解锁？',
            confirm_remove_scene: '若删除该场景，该房间的场景将会失效，确定删除？',
            confirm_remove_all: '批量删除场景，只会删除未锁定的场景，确认删除？',
            remove_success: '移除成功！',
            copy_success: '复制成功！',
            effective: '有效',
            invalid: '无效',
            pc_side: 'PC端',
            app_side: 'APP端',
            state_change_succss: '状态更改成功！',
            scene_model_no_null: '场景模式不能为空！',
            model_name_no_equals: '模式名称不能相同！',
            eq_location_device_tip: '同一位置设备类型只能添加一次！',
            sel_trigger_device: '请选择触发设备！',
            sel_execute_device: '请选择执行设备！',
            confirm_trigger_device: '请确认触发设备！',
            confirm_execute_device: '请确认执行设备！',
            confirmHotelName: '该场景下没有可复制的设备！',
            select_room: '请选择房间！',
            trigger_device_no_null: '触发设备不能为空！',
            execute_device_no_null: '执行设备不能为空！',
            app_room_no_null: '应用房间不能为空！',
            remark_len_no_300: '备注字数不能超过300！',
            no_device: '未匹配到设备！',
            unknown_location: '未知位置',
            sel_dev_property: '请选择设备属性！',
            dev_type_add_once: '同一设备类型只能添加一次！',
            seat1: '（第一位）',
            seat2: '（第二位）',
            seat3: '（第三位）',
            seat4: '（第四位）',
            seat5: '（第五位）',
            seat6: '（第六位）',
            select_copy_room_num: '请选择要复制的房间号！',
            sceneNew: true, // 新建--按钮
            sceneDetail: true, // 详情--按钮
            sceneDelete: true, // 删除--按钮
            sceneEdit: true, // 编辑--按钮
            sceneCopy: true, // 复制--按钮
            sceneStatus: true, // 状态更改--按钮
            sceneOnekeyCopy: true, // 一键复制--按钮
            sceneSync: true, // 同步--按钮
            sceneLock: true, // 锁定--按钮
            sceneBatchDelete: true, // 批量删除--按钮
            sceneBatchunLock: true, // 批量解锁--按钮
            sceneBatchLock: true, // 批量锁定--按钮
            loading: true,
            triggerWidth: 0,
            operWidth: 0,
            syncWidth: 0,
            total: 0, // 表格数据总数
            limit: 0, // 每页显示数
            page: 1, // 当前页
            hotelVal: '', // 酒店id
            hotelName: '', // 酒店name
            shotelName: '', // 搜索酒店name
            hotelValRoom: '', // 应用房间酒店
            hotelValRooms: '', // 应用房间酒店
            // hotelValCopy: '', // 场景复制酒店
            roomVal: '', // 房间号
            roomStr: '', // 房间id
            statusVal: '', // 状态
            createTypeVal: '', // 创建类型
            scenes: '', // 场景模式
            copyScene: false, // 一键复制场景
            dCopyModel: '', // 样板房下拉选中(一键复制)
            dExampleRoom: false, // 编辑样板房(一键复制)
            dTargetRoom: false, // 添加目标房间(一键复制)
            dMatchingTest: false, // 匹配测试(一键复制)
            dMatchingCatch: false, // 匹配错误修改(一键复制)
            addRoom: false, // 添加样板房(一键复制)
            dExampName: '', // 样板房名称
            dExampId: '', // 样板房编号
            examp_name_no_null: '样板房名称不能为空！',
            examp_id_no_null: '样板房编号不能为空！',
            room_type_no_null_a: '房间类型不能为空！',
            floor_no_null: '楼层不能为空！',
            dev_location_no_null: '设备位置不能为空！',
            room_no_null: '房间号不能为空！',
            select_hotel: '请选择样酒店！',
            select_examp: '请选择样板房！',
            select_target_room: '请选择目标房间！',
            select_remove_scene: '请选择要删除的场景！',
            select_lock_scene: '请选择要锁定的场景！',
            select_unlock_scene: '请选择要解锁的场景！',
            hotelValExample: '', // 酒店编号(一键复制)
            exampleroomTemplateId: '', // 样板房id(一键复制)
            exampleTypeRoomId: '', // 样板房下的房型id(一键复制)
            exampleRoomId: '', // 样板房下的房间(一键复制)
            exampleName: '', // 样板房名称(一键复制)
            dExampleTypeVal: '', // 房间类型(添加样板房)
            dExampleVal: '', // 房间号(添加样板房)
            dExampleTypeOpt: [], // 酒店下房间类型(添加样板房)
            dExampleOpt: [], // 酒店下房间号(添加样板房)
            ExampleRomm: [], // 动态房间号(板房列表)
            dExampType: [], // 当前样板房
            dExatypeVal: '',
            dExaroomVal: '',
            activeNames: ['0'],
            dExampAllSelect: false, // 目标房间全选
            collapseImg: false, // 加载图片
            collapseState: false, // 加载状态
            MatchState: false, // 匹配结果
            MatchStateNubmer: 0, // 匹配结果
            MatchCategory: true, // 复制结果
            matchingState: false, // 匹配成功
            MatRoomArr: [],
            newType: [],
            dExampRoomArr: [], // 样板房下目标房间
            MatchingRoomArr: [], // 选中的目标房间
            dEditFloorVal: '', // 楼层(测试)
            dEditLocationVal: '', // 设备位置(测试)
            dEditRoomNoId: '', // 房间id(测试)
            dEditRoomNoVal: '', // 房间号(测试)
            dEditAliasVal: '', // 设备别名(测试)
            matchingMac: '', // mac地址(测试)
            multipleSelection: [], // 列表选中
            numFlag: 0, // 设备位数
            aliasArrVal: ['—', '—', '—', '—'], // 对应别名
            dEditFloorOpt: [],
            dEditLocationOpt: [],
            addScene: false, // 新建场景
            dialogDetail: false, // 详情
            dialogEdit: false, // 编辑
            dCopyRoom: false, // 复制
            copySave: false, // 复制保存加载状态
            matchingSave: false, // 匹配测试加载状态
            matchingSave1: false, // 开始复制(匹配)加载状态
            matchingSaves: false, // 重新匹配加载状态
            matchingSave2: false, // 开始复制加载状态
            dDetHotelVal: '—', // 所属酒店(详情)
            dDetStatusVal: '—', // 状态(详情)
            dDetLockedVal: '—', // 是否锁定(详情)
            dDetSyncStateVal: '—', // 是否同步(详情)
            dDetSceneActionVal: '—', // 支持动作(详情)
            dDetRoomVal: '—', // 应用房间(详情)
            dDetTypeVal: '—', // 创建类型(详情)
            dDetModelVal: '—', // 场景模式(详情)
            dDetTimeVal: '—', // 场景关闭时间(详情)
            dDetDelayVal: '—', // 场景延时时间(详情)
            dDetAddTimeVal: '—', // 添加时间(详情)
            dDetRemarkVal: '—', // 备注信息(详情)
            dDetSyncDeviceVal: '—', // 同步设备(详情)
            dDetTriggerDevVal: '—', // 触发设备(详情)
            dDetExecuteDevVal: '—',
            dDetTriggerConVal: [], // 被执行设备(详情)
            dSceneCloseTime: '', // 场景关闭时间
            isEffective: false, // 是否生效
            remarks: '', // 备注信息
            hotelOpt: [], // 酒店下拉(表头)
            roomOpt: [], // 房间号下拉(表头)
            floorOpt: [], // 楼层下拉框(表头)
            floorType: [
               {
                  value: '',
                  label: '全部'
               }
            ],
            buildingUnit: '', // 楼栋/单元(表头)
            buildingList: [],
            dModelEdit: false, // 场景模式--编辑框(新建场景)
            dTriggerAdd: false, // 触发设备--添加框(新建场景)
            dExecuteAdd: false, // 执行设备--添加框(新建场景)
            dAppRoom: false, // 应用房间--添加框(新建场景)
            dSceneModel: '', // 场景模式(新建场景)
            newModel: [], // 场景模式--编辑--模式列表(新建场景)
            dTriggerDevVal: '', // 触发设备(新建场景)
            dExecuteDevVal: '', // 执行设备(新建场景)
            dTriggerDevCode: '', // 触发设备编码(新建场景)
            dExecuteDevCode: '', // 执行设备编码(新建场景)
            dTriggerLocationVal: '', // 触发设备--添加--选择位置(新建场景)
            dTriggerLocationOpt: [], // 触发设备--添加--设备列表(新建场景)
            dExecuteLocationVal: '', // 执行设备--添加--选择位置(新建场景)
            dExecuteLocationOpt: [], // 执行设备--添加--设备列表(新建场景)
            triggerDevConfirmArr: [], // 新建场景--添加触发设备--触发设备确认
            executeDevConfirmArr: [], // 新建场景--添加执行设备--执行设备确认
            triggerDevConfirmArrCn: [],
            executeDevConfirmArrCn: [],
            dAppRoomVal: '', // 应用房间(新建场景)
            dAppRoomId: '', // 应用房间ID(新建场景)
            addAllSelect: false, // 全选(新建场景)
            copyAllSelect: false, // 全选(复制场景)
            dAppRoomList: [], // 应用房间--添加--房间列表
            dAppRoomListId: '', // 房间id
            dAppRoomListName: '', // 房间Name
            dSwitchVal: true, // 是否生效(新建场景)
            dSceneTimeVal: '0', // 场景关闭时间(新建场景)
            dSceneDelayVal: '0', // 场景延时时间(新建场景)
            dRemarkVal: '', // 备注信息(新建场景)
            dSceneModelOpt: [], // 场景模式下拉框(新建场景)
            dEditSceneModel: '', // 场景模式(编辑场景)
            dEditTriggerDevVal: '', // 触发设备(编辑场景)
            dEditExecuteDevVal: '', // 执行设备(编辑场景)
            dEditAppRoomVal: '', // 应用房间(编辑场景)
            dEditSwitchVal: false, // 是否生效(编辑场景)
            dEditSceneTimeVal: '', // 场景关闭时间(编辑场景)
            dEditSceneDelayedVal: '', // 场景延时时间(编辑场景)
            dEditRemarkVal: '', // 备注信息(编辑场景)
            dEditSceneModelOpt: [], // 场景模式下拉框(编辑场景)
            dEditSceneTimeOpt: [], // 场景关闭时间下拉框(编辑场景)
            dEditSceneDelayedOpt: [], // 场景延时时间下拉框(编辑场景)
            dFloorVal: '', // 楼层(应用房间添加)
            dRoomTypeVal: '', // 房间类型(应用房间添加)
            dRoomTypeOpt: [], // 房间类型下拉框(应用房间添加)
            dFloorOpt: [], // 楼层(应用房间添加)
            dExecuteEdit: false, // 执行设备编辑(编辑场景)
            dEditCustomVal: '', // 自定义
            triggerDevList: [], // 添加触发设备列表(触发设备添加)
            executeDevList: [], // 添加执行设备列表(执行设备添加)
            dTriggerEdit: false, // 被触发设备编辑(编辑场景)
            dEditTypeCode: '', // 创建类型编码
            dEditRoomId: '', // 编辑场景--触发设备--编辑--房间ID
            dEditSceneId: '', // 编辑--场景ID
            dEditHotelId: '', // 编辑--酒店ID
            dEditSceneType: '', // 编辑--场景类型
            noDevTip: true, // 提示标记
            dEditTriggerDevList: [], // 编辑触发设备(编辑场景)
            dEditExecuteDevList: [], // 编辑执行设备(编辑场景)
            triggerRadio: '00', // 编辑场景--触发设备--编辑--设备属性
            executeRadio: '00', // 编辑场景--执行设备--编辑--设备属性
            dEditDeviceAttr: [], // 编辑场景--触发设备--编辑--设备属性列表
            appModelOpt: [
               {
                  value: '1',
                  label: '欢迎模式'
               },
               {
                  value: '2',
                  label: '离开模式'
               },
               {
                  value: '3',
                  label: '起床模式'
               },
               {
                  value: '4',
                  label: '睡眠模式'
               },
               {
                  value: '5',
                  label: '自定义'
               }
            ], // 场景模式下拉框(APP端)
            editTriggerDevConfirmArr: [], // 编辑场景--添加触发设备--触发设备确认
            editExecuteDevConfirmArr: [], // 编辑场景--添加执行设备--执行设备确认
            editTriggerStr: '', // 触发设备临时字符串
            editExecuteStr: '', // 执行设备临时字符串
            editSaveTriggerDevStr: '', // 触发--位置、名称、别名、属性(用于保存)
            editSaveExecuteDevStr: '', // 执行--位置、名称、别名、属性(用于保存)
            editSaveTriggerCodePropertyStr: '', // 触发--编码、属性(用于保存)
            editSaveExecuteCodePropertyStr: '', // 执行--编码、属性(用于保存)
            editSaveTriggerCodeStr: '', // 触发--编码(用于保存)
            editSaveExecuteCodeStr: '', // 执行--编码(用于保存)
            editSaveTriggerIndexStr: '', // 触发--位数索引(用于保存)
            editSaveExecuteIndexStr: '', // 执行--位数索引(用于保存)
            tempTriggerCodeArr: [], // 编辑场景--触发设备编码
            tempExecuteCodeArr: [], // 编辑场景--执行设备编码
            tempTriggerArr: [], // 编辑场景--触发设备文本(用于选中)
            tempExecuteArr: [], // 编辑场景--执行设备文本(用于选中)
            dCopyRoomArr: [], // 复制--房间号
            dCopySceneId: '', // 复制--场景ID
            conditionIndex: '',
            conditionDeviceName: '',
            dCopyResult: false, // 复制结果
            dCopySuccessRoom: [], // 复制成功房间
            dCopyFailureRoom: [], // 复制失败房间
            tableData: []
         }
      },
      beforeRouteEnter(to, from, next){
         if (from.name === 'hotelScenesEdit'){
            next(vm => {
               vm.getSceneList()
            })
            return
         }
         next()
      },
      beforeRouteLeave(to, from, next){
         if (to.name === 'hotelScenesEdit' || to.name === 'hotelScenesDetail'){
            this.$store.dispatch('setCachePage', from.name)
            next()
            return
         }
         this.$store.dispatch('setCachePage', 'fakePage')
         next()
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1 // 每页显示数
         this.hotel_list() // 酒店(下拉框)
      },
      methods: {
         pageChange (num) {
            this.limit = num
            this.getSceneList()
         },
         currChange (num) {
            this.page = num
            this.getSceneList()
         },
         handleSelectionChange (val) {
            let arr = []
            let obj = {}
            val.forEach((item, index) => {
               obj.scene_id = item.scene_id
               arr.push(obj)
               obj = {}
            })
            this.multipleSelection = arr
         },
         filterStr(str) {
            if (!str || str === 'null' || str + '' === 'undefined') {
               return '—'
            }
            return str
         },
         filterStrbmer (str) {
            if (!str || str === 'null' || str + '' === 'undefined') {
               return ''
            }
            return str
         },
         // 搜索
         handleSearch () {
            this.page = 1
            this.getSceneList()
         },
         // 重置(表头)
         handleReset () {
            this.buildingUnit = '' // 楼栋
            this.roomVal = '' // 房间号
            this.roomStr = ''
            this.roomOpt = []
            this.statusVal = '' // 状态
            this.floorVal = ''
            this.floorOpt = JSON.parse(JSON.stringify(this.floorType))
            this.createTypeVal = '' // 创建类型
            this.scenes = '' // 场景模式
         },
         // 场景列表
         getSceneList () {
            const url = urlObj.getScenePage + `?limit=${this.limit}&page=${this.page}`
            let param = {
               roomId: this.roomVal,
               buildUnitId: this.buildingUnit[1], // 单元ID
               floorNo: this.floorVal,  // 楼层
               state: this.statusVal, // 状态
               sceneName: this.scenes // 场景模式
            }
            if (!this.hotelVal) {
          /*     let hotelStr = []
               this.hotelOpt.forEach((item, index) => {
                  if (item.value) {
                     hotelStr.push(item.value)
                  }
               })
               if (hotelStr.length > 0){
                  param.hotelIds = hotelStr
               }*/
               param.hotelIds = [this.hotelInfo.id]
            }else {
               param.hotelId = this.hotelVal
            }
            this.$axios.post(url, param, 'json').then((res) => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let records = res.records
                  if (!records || records.length <= 0) {
                     this.currPage = 1
                     this.tableData = []
                     return
                  }
                  if (res.pageCount < this.currPage) {
                     this.currPage = res.pageCount
                  }
                  let td = {}
                  let tempArr = []
                  for (var i = 0; i < records.length; i++) {
                     td.scene_id = this.filterStr(records[i].id) // 场景ID
                     td.hotel_id = this.filterStr(records[i].hotelId) // 酒店ID
                     td.own_hotel = this.filterStr(records[i].hotelName) // 所属酒店
                     td.room_id = this.filterStr(records[i].roomId) // 房间ID
                     td.room_type_id = this.filterStr(records[i].roomTypeId) // 房型ID
                     td.room_num = this.filterStr(records[i].roomNo) // 房间号
                     td.floor_num = this.filterStr(records[i].floorNo) // 楼层
                     td.create_code = this.filterStr(records[i].createType) // 创建类型编码
                     // 创建类型
                     if (records[i].createType === 2) {
                        td.create_type = this.pc_side
                     } else if (records[i].createType === 3) {
                        td.create_type = this.app_side
                     } else {
                        td.create_type = '—'
                     }
                     td.scene_type = this.filterStr(records[i].sceneType) // 场景类型
                     // modObj.value = records[i].id
                     // modObj.label = this.filterStr(records[i].sceneName)
                     td.scene_model = this.filterStr(records[i].sceneName) // 场景模式
                     // td.trigger_device = this.filterStr(records[i].sceneCondition) // 触发设备
                     // td.scene_model = this.filterStr(records[i].sceneEnName) // 场景模式--英文
                     // td.executed_device = this.filterStr(records[i].sceneTouch) // 被执行设备
                     // 状态/更改状态
                     if (records[i].sceneState === true) {
                        td.status = 1
                     } else {
                        td.status = 0
                     }
                     td.lockedSwitch = records[i].locked // 锁定开关
                     td.stateSwitch = records[i].state // 状态开关
                     td.syncStatus = this.filterStrbmer(records[i].syncGatewayState) // 同步状态
                     td.conditionIndex = this.filterStrbmer(records[i].conditionIndex)
                     td.conditionDeviceName = this.filterStrbmer(records[i].conditionDeviceName)
                     td.triggerDeviceName = this.filterStrbmer(records[i].triggerDeviceName)
                     td.createTime = this.filterStr(records[i].createTime) // 创建时间
                     tempArr.push(td)
                     // modelArr.push(modObj)
                     td = {}
                     // modObj = {}
                  }
                  this.tableData = tempArr
                  // this.dSceneModelOpt = modelArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 酒店(下拉框)
         hotel_list () {
            let hotUrl = urlObj.getHotelList
            let hotParam = {}
            this.$axios.post(hotUrl, hotParam, 'json').then((res) => {
               if (res.success) {
                  // console.log(res)
                  let dataObj = res.records
                  if (!dataObj || dataObj.length <= 0) {
                     return
                  }
                  let tempArr = JSON.parse(JSON.stringify(this.floorType))
                  let tempObj = {}
                  for (var i = 0; i < dataObj.length; i++) {
                     tempObj.value = dataObj[i].id
                     tempObj.label = dataObj[i].hotelName
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.hotelOpt = tempArr // 酒店下拉框(表头)
                  this.hotelVal = tempArr[0].value
                  // this.shotelName = tempArr[0].label
                  // this.hotelName = tempArr[0].label
                  this.hotelValRoom = dataObj[0].id
                  this.hotelValExample = dataObj[0].id
                  // this.floor_list() // 楼层下拉框(表头)
                  // this.building_list()
                  // this.room_list() // 房间号(表头)
                  this.getSceneList() // 场景列表
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 酒店下拉框(表头)改变
         hotel_change (val) {
            if (!val) {
               this.buildingList = []
               return
            }
            this.roomVal = ''
            this.floorVal = ''
            this.roomStr = ''
            this.floorOpt = JSON.parse(JSON.stringify(this.floorType))
            this.building_list()
            // this.hotelOpt.forEach((item, index) => {
            //   if (val === item.value) {
            //     this.hotelName = item.label
            //   }
            // })
            // this.room_list() // 房间号(下拉框)
         },
         // 获取楼栋单元层级
         building_list () {
            let storeUrl = urlObj.buildAndUnitList + `/${this.hotelVal}`
            let storeParam = {}
            this.buildingChange('')
            this.$axios.get(storeUrl, storeParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let records = res.records
                  if (!records || records.length <= 0) {
                     this.buildingList = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (let i = 0; i < records.length; i++) {
                     tempObj.value = records[i].id
                     tempObj.label = records[i].buildName
                     let children = []
                     let childObj = {}
                     for (let j = 0; j < records[i].buildUnits.length; j++) {
                        childObj.value = records[i].buildUnits[j].id
                        childObj.label = records[i].buildUnits[j].unitName
                        children.push(childObj)
                        childObj = {}
                     }
                     tempObj.children = children
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.buildingList = tempArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取楼栋单元层级
         buildingChange (val) {
            if(val.length > 0) {
               this.floor_list(val[1])
               this.room_list() // 房间号
            }
         },
         // 房间号下拉框(表头)
         room_list () {
            let rooUrl = urlObj.getRoomLists
            let rooParam = {
               hotelId: this.hotelVal, // 酒店
               buildUnitId: this.buildingUnit[1], // 单元ID
               floor: this.floorVal // 楼层
            }
            this.$axios.post(rooUrl, rooParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let arr = res.records
                  if (!arr || arr.length <= 0) {
                     this.roomOpt = []
                     // this.roomStr = ''
                     // this.tableData = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObj.value = arr[i].id
                     tempObj.label = arr[i].roomNo
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.roomOpt = tempArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 楼层点击
         getFloorClick(v) {
            this.floorVal = v.floor
            this.roomVal = ''
            this.room_list() // 房间号
            this.getSceneList()
         },
         // 楼层下拉框(表头)
         floor_list (unitVal) {
            let floUrl = urlObj.getUnit + `/${unitVal}`
            let floParam = {}
            this.$axios.get(floUrl, floParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let data = res.records
                  let beginnum = Number(data.beginFloor)
                  let num = Number(data.endFloor)
                  if (!num || num <= 0) {
                     this.floorOpt = JSON.parse(JSON.stringify(this.floorType))
                     return
                  }
                  let tempArr = JSON.parse(JSON.stringify(this.floorType))
                  let tempObj = {}
                  for (var i = beginnum; i <= num; i++) {
                     tempObj.value = i
                     tempObj.label = i
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  let newArr = tempArr.filter(item => {
                     return item.label > 0 || item.label === '全部'
                  })
                  this.floorOpt = newArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 复制
         copy_scene () {
            this.$router.push('/hotel_scenes_copy')
         },
         filterStatus(tip) {
            let msg = ''
            // 判断语言
            if (localStorage.lang === 'zh_CN') {
               switch (tip) {
                  case 'param_error':
                     msg = '参数错误，不能为空！'
                     break
                  case 'log_out':
                     msg = '登录已过期，请重新登录！'
                     sessionStorage.setItem('router', '/login')
                     break
                  case 'tip_1':
                     msg = '验证码已过期！'
                     break
                  case 'tip_2':
                     msg = '验证码错误！'
                     break
                  case 'tip_3':
                     msg = '你没有登录权限，请联系管理员！'
                     break
                  case 'tip_4':
                     msg = '用户名或者密码错误，请重新登录！'
                     break
                  case 'tip_5':
                     msg = '没有找到相关设备！'
                     break
                  case 'tip_6':
                     msg = '设备已离线,请确认该设备的状态！'
                     break
                  case 'tip_7':
                     msg = '请勿重复提交！'
                     break
                  case 'tip_8':
                     msg = '酒店名称已存在！'
                     break
                  case 'tip_9':
                     msg = '该房间有设备，不能删除，请确认！'
                     break
                  case 'tip_10':
                     msg = '该酒店的房间号已经存在！'
                     break
                  case 'tip_11':
                     msg = '该楼层已存在你输入的房间范围，请核对！'
                     break
                  case 'tip_12':
                     msg = '角色名称已存在！'
                     break
                  case 'tip_13':
                     msg = '用户登录名已存在！'
                     break
                  case 'tip_14':
                     msg = '旧密码不正确，请确认！'
                     break
                  case 'tip_15':
                     msg = '该手机号不存在，请确认！'
                     break
                  case 'tip_16':
                     msg = '验证码发送失败，请重新发送！'
                     break
                  case 'tip_17':
                     msg = '新手机号已被注册使用，请确认！'
                     break
                  case 'tip_18':
                     msg = '验证码不正确或已过期！'
                     break
                  case 'tip_19':
                     msg = '用户手机号已被注册使用！'
                     break
                  case 'tip_20':
                     msg = '用户邮箱已被注册使用！'
                     break
                  case 'tip_21':
                     msg = '触发设备，已经配置了场景，请确认！'
                     break
                  case 'tip_22':
                     msg = '该酒店下的部门名称已存在！'
                     break
                  case 'tip_23':
                     msg = '该部门挂载着用户，不能删除！'
                     break
                  case 'tip_24':
                     msg = '该角色下挂载着用户，不能删除！'
                     break
                  case 'tip_25':
                     msg = '该酒店下面挂载下级酒店，不能删除！'
                     break
                  case 'tip_26':
                     msg = '该酒店下面挂载部门，不能删除！'
                     break
                  case 'tip_27':
                     msg = '该酒店下面挂载用户，不能删除！'
                     break
                  case 'tip_28':
                     msg = '该酒店下面有设备，不能删除！'
                     break
                  case 'tip_30':
                     msg = '该房间类型下面有房间，不能删除！'
                     break
                  case 'tip_31':
                     msg = '该房间的小度已被添加，请确认！'
                     break
                  case 'tip_32':
                     msg = '该房间的天猫已被添加，请确认！'
                     break
                  case 'tip_33':
                     msg = '该场景是锁定状态，不能编辑或删除，请确认！'
                     break
                  case 'tip_34':
                     msg = '该酒店下的房间或房型已经创建样板房，请确认！'
                     break
                  case 'tip_35':
                     msg = '该样板房没有场景！'
                     break
                  case 'tip_36':
                     msg = '该酒店下的样板房名称已存在！'
                     break
                  case 'tip_37':
                     msg = '该场景不存在，请确认！'
                     break
                  case 'tip_38':
                     msg = '该场景里面没有设备，为无效场景！'
                     break
                  case 'tip_39':
                     msg = '触发设备为空，为无效场景，不能同步！'
                     break
                  case 'tip_40':
                     msg = '被触发设备为空，为无效场景，不能同步！'
                     break
                  case 'tip_41':
                     msg = '配置的场景中网关不存在，请确认！'
                     break
                  case 'tip_42':
                     msg = '该场景是跨网关配置，其中一个网关的触发设备为空，数据不能同步到网关！'
                     break
                  case 'tip_43':
                     msg = '该场景是跨网关配置，其中一个网关的被触发设备为空，数据不能同步到网关！'
                     break
                  case 'tip_44':
                     msg = '该场景为无效状态，不能同步！'
                     break
                  case 'data_not_exist':
                     msg = '数据不存在'
                     break
                  default:
                     msg = '系统错误，请联系管理员！'
                     break
               }
            } else {
               switch (tip) {
                  case 'param_error':
                     msg = 'The parameter is incorrect and cannot be empty！'
                     break
                  case 'log_out':
                     msg = 'Login has expired, please log in again！'
                     sessionStorage.setItem('router', '/login')
                     break
                  case 'tip_1':
                     msg = 'The verification code has expired！'
                     break
                  case 'tip_2':
                     msg = 'Verification code error！'
                     break
                  case 'tip_3':
                     msg = 'You do not have login privileges, please contact the administrator！'
                     break
                  case 'tip_4':
                     msg = 'The username or password is incorrect. Please log in again！'
                     break
                  case 'tip_5':
                     msg = 'No related device found！'
                     break
                  case 'tip_6':
                     msg = 'The device is offline. Please confirm the status of the device.！'
                     break
                  case 'tip_7':
                     msg = 'Do not submit again！'
                     break
                  case 'tip_8':
                     msg = 'Hotel name already exists！'
                     break
                  case 'tip_9':
                     msg = 'This room has device and cannot be deleted. Please confirm！'
                     break
                  case 'tip_10':
                     msg = 'The hotel room number already exists！'
                     break
                  case 'tip_11':
                     msg = 'The room range you entered already exists on this floor, please check！'
                     break
                  case 'tip_12':
                     msg = 'Role name already exists！'
                     break
                  case 'tip_13':
                     msg = 'User login already exists！'
                     break
                  case 'tip_14':
                     msg = 'The old password is incorrect, please confirm！'
                     break
                  case 'tip_15':
                     msg = 'The phone number does not exist, please confirm！'
                     break
                  case 'tip_16':
                     msg = 'Verification code failed to be sent, please resend！'
                     break
                  case 'tip_17':
                     msg = 'The new mobile number has been registered, please confirm！'
                     break
                  case 'tip_18':
                     msg = 'The verification code is incorrect or has expired！'
                     break
                  case 'tip_19':
                     msg = 'User phone number has been registered for use！'
                     break
                  case 'tip_20':
                     msg = 'User mailbox has been registered for use！'
                     break
                  case 'tip_21':
                     msg = 'Trigger device, the scene has been configured, please confirm！'
                     break
                  case 'tip_22':
                     msg = 'The department name under the hotel already exists！'
                     break
                  case 'tip_23':
                     msg = 'The department is attached to the user and cannot be deleted！'
                     break
                  case 'tip_24':
                     msg = 'The user is mounted under this role and cannot be deleted！'
                     break
                  case 'tip_25':
                     msg = 'The lower level hotel is listed below the hotel and cannot be deleted！'
                     break
                  case 'tip_26':
                     msg = 'The hotel is attached to the department below and cannot be deleted！'
                     break
                  case 'tip_27':
                     msg = 'The user is mounted below the hotel and cannot be deleted！'
                     break
                  case 'tip_28':
                     msg = 'There are equipment under the hotel and cannot be delete！'
                     break
                  case 'tip_30':
                     msg = 'There is a room under the room type, cannot be deleted!'
                     break
                  case 'tip_31':
                     msg = 'The room dimension has been added, please confirm！'
                     break
                  case 'tip_32':
                     msg = 'Tmall of this room has been added, please confirm！'
                     break
                  case 'tip_33':
                     msg = 'The scene is locked and cannot be edited or deleted. Please confirm！'
                     break
                  case 'tip_34':
                     msg = 'The room or room type under this hotel has been created. Please confirm！'
                     break
                  case 'tip_35':
                     msg = 'The model room has no scene！'
                     break
                  case 'tip_36':
                     msg = 'The name of the model room under the hotel already exists！'
                     break
                  case 'tip_37':
                     msg = 'This scenario does not exist, please confirm！'
                     break
                  case 'tip_38':
                     msg = 'There is no device in this scene, it is an invalid scene！'
                     break
                  case 'tip_39':
                     msg = 'Trigger device is empty, invalid scene, cannot synchronize！'
                     break
                  case 'tip_40':
                     msg = 'Triggered device is empty, invalid scene, cannot synchronize！'
                     break
                  case 'tip_41':
                     msg = 'The gateway does not exist in the configured scenario, please confirm！'
                     break
                  case 'tip_42':
                     msg = 'The scenario is a cross-gateway configuration in which the trigger device for one gateway is empty and the data cannot be synchronized to the gateway！'
                     break
                  case 'tip_43':
                     msg = 'The scenario is a cross-gateway configuration in which the triggered device of one gateway is empty and the data cannot be synchronized to the gateway！'
                     break
                  case 'tip_44':
                     msg = 'This scenario is in an invalid state and cannot be synchronized！'
                     break
                  case 'data_not_exist':
                     msg = 'Data does not exist'
                     break
                  default:
                     msg = 'System error, please contact the administrator！'
                     break
               }
            }
            return msg
         },
         // 详情
         scenes_detail (row) {
            this.$router.push('/hotel_scenes_detail?id=' + row.scene_id + '&type=2&hotelName=' + row.own_hotel)
         },
         // 编辑查询
         scenes_edit (row) {
            if (row) {
               this.$router.push('/hotel_scenes_edit?id=' + row.scene_id + '&type=2&hotelName=' + row.own_hotel)
            } else {
               this.$router.push('/hotel_scenes_edit?type=2')
            }
         },
         // 一键复制场景(显示)
         copy_scene_a () {
            this.copyScene = true
            this.hotelValExample = this.hotelOpt[0].value
            // this.hotelValExample = this.hotelOpt[9].value
            this.dCopyModel = '' // 样版房下拉选择
            this.dExampRoomArr = [] // 目标房间
            this.matchingSave = false
            this.matchingSaves = false
            this.matchingSave1 = false
            this.matchingSave2 = false
            this.matchingState = false
            this.dExampleRoom_list()
         },
         // 酒店下拉(一键复制)
         example_change () {
            this.dCopyModel = '' // 样版房下拉选择
            this.dExampRoomArr = [] // 目标房间
            this.dExampleRoom_list()
         },
         // 样版房下拉(一键复制)
         example_copy_change (val) {
            // console.log(val)
            // let exampleIndex = 0
            this.dExampType.forEach((item, index) => {
               if (val === item.roomId) {
                  // let exampleIndex = index
                  this.exampleroomTemplateId = item.id
                  this.exampleTypeRoomId = item.roomTypeId
                  this.exampleRoomId = item.roomId
                  this.exampleName = item.dExampName
               }
            })
            this.dExampRoomArr_list()
         },
         // 样板房(编辑)
         example_edit () {
            this.dExampleRoom = true
            // this.dExampleRoom_list()
            this.get_room_type_example() // 房型
         },
         // 设备位置
         dLocation_list () {
            let attUrl = urlObj.site + urlObj.getAttrList
            let attParam = {
               dictType: 1
            }
            this.$axios.post(attUrl, attParam).then((res) => {
               if (res.msg === 'success') {
                  let arr = res
                  if (!res || arr.length <= 0) {
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < arr.length; i++) {
                     if (localStorage.lang === 'cn') {
                        tempObj.value = arr[i].dictValue
                        tempObj.label = arr[i].dictValue
                     } else {
                        tempObj.value = arr[i].dictEnValue
                        tempObj.label = arr[i].dictEnValue
                     }
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dEditLocationOpt = tempArr
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 样板房列表
         dExampleRoom_list () {
            let url = urlObj.site + urlObj.queryRoomTemplateList
            let param = {
               hotelId: this.hotelValExample // 酒店id
            }
            this.$axios.post(url, param).then((res) => {
               if (res.msg === 'success') {
                  let arr = res
                  if (!res || arr.length <= 0) {
                     this.dCopyModel = ''
                     this.dExampType = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (let i = 0; i < arr.length; i++) {
                     tempObj.id = arr[i].id
                     tempObj.dExampName = arr[i].templateName
                     tempObj.dExampId = arr[i].templateCode
                     tempObj.roomTypeId = arr[i].roomTypeId
                     tempObj.roomId = arr[i].roomId
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dExampType = tempArr
               } else {
                  this.$message({
                     showClose: true,
                     message: this.filterStatus(res.msg),
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 目标房间列表
         dExampRoomArr_list () {
            let url = urlObj.site + urlObj.getTargetRoom
            let param = {
               roomTemplateId: this.exampleroomTemplateId // 样板房id
            }
            this.$axios.get(url, param).then((res) => {
               if (res.msg === 'success') {
                  let arr = res
                  if (!res || arr.length <= 0) {
                     this.dExampRoomArr = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObj.roomId = arr[i].roomId
                     tempObj.checked = false
                     tempObj.roomNo = arr[i].roomNo
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dExampRoomArr = tempArr
               } else {
                  this.$message({
                     showClose: true,
                     message: this.filterStatus(res.msg),
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 样板房(修改)
         editdExamp (id, dExampName, dExampId, roomTypeId, roomId) {
            // console.log(dTypeCnVal, dTypeEnVal)
            if (this.dExamp_save_info(dExampName, dExampId, roomTypeId, roomId)) {
               this.$confirm(this.confirm_edit, this.prompt, {
                  confirmButtonText: this.confirm,
                  cancelButtonText: this.cancel,
                  type: 'info'
               }).then(async () => {
                  let editUrl = urlObj.site + urlObj.modifyRoomTemplate
                  let editParam = {
                     id: id, // 样板房id
                     templateName: dExampName, // 样板房名称
                     templateCode: dExampId, // 样板房编号
                     roomTypeId: roomTypeId, // 房型
                     roomId: roomId, // 房间
                     hotelId: this.hotelValExample // 酒店id
                  }
                  this.$axios.post(editUrl, editParam).then((res) => {
                     if (res.msg === 'success') {
                        // console.log(res)
                        this.$message({
                           showClose: true,
                           message: this.update_success,
                           type: 'success'
                        })
                        this.dExampleRoom_list()
                     } else {
                        this.$alert(this.filterStatus(res.msg), '', {
                           confirmButtonText: this.confirm,
                           type: 'error'
                        })
                     }
                  }).catch(error => {
                     console.log(error)
                  })
               }).catch(cancel => {
                  console.log(cancel)
               })
            }
         },
         // 样板房(删除)
         deldExamp (id) {
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(async () => {
               let delUrl = urlObj.site + urlObj.deleteRoomTemplate
               let delParam = {
                  templateId: id // 房型id
               }
               this.$axios.get(delUrl, delParam).then((res) => {
                  if (res.msg === 'success') {
                     // console.log(res)
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.dExampleRoom_list()
                  } else {
                     this.$alert(this.filterStatus(res.msg), '', {
                        confirmButtonText: this.confirm,
                        type: 'error'
                     })
                  }
               }).catch(error => {
                  console.log(error)
               })
            }).catch(cancel => {
               console.log(cancel)
            })
         },
         // 添加样板房(弹框形式展开)
         add_type_dExamp () {
            this.dExamp_reset()
            this.addRoom = true
         },
         // 房间类型(联动房间)
         dExample_change () {
            this.dExampleVal = ''
         },
         // 添加样板房(重置)
         dExamp_reset () {
            this.dExampName = ''
            this.dExampId = ''
            this.dExampleTypeVal = ''
            this.dExampleVal = ''
         },
         // 添加样板房型(保存)
         dExamp_save () {
            if (this.dExamp_save_info(this.dExampName, this.dExampId, this.dExampleTypeVal, this.dExampleVal)) {
               let addUrl = urlObj.site + urlObj.addRoomTemplate
               let addParam = {
                  templateName: this.dExampName, // 样板房名称
                  templateCode: this.dExampId, // 样板房编号
                  roomTypeId: this.dExampleTypeVal, // 房型
                  roomId: this.dExampleVal, // 房间
                  hotelId: this.hotelValExample // 酒店id
               }
               this.$axios.post(addUrl, addParam).then((res) => {
                  if (res.msg === 'success') {
                     this.$message({
                        showClose: true,
                        message: this.add_success,
                        type: 'success'
                     })
                     this.addRoom = false
                     this.dExamp_reset()
                     this.dExampleRoom_list()
                  } else {
                     this.$alert(this.filterStatus(res.msg), '', {
                        confirmButtonText: this.confirm,
                        type: 'error'
                     })
                  }
               }).catch(error => {
                  console.log(error)
               })
            }
         },
         // 添加样板房提示语句(保存)
         dExamp_save_info (dExampName, dExampId, dExampleTypeVal, dExampleVal) {
            if (!dExampName) {
               this.$alert(this.examp_name_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!dExampId) {
               this.$alert(this.examp_id_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!dExampleTypeVal) {
               this.$alert(this.room_type_no_null_a, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!dExampleVal) {
               this.$alert(this.room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            return true
         },
         // 目标房间(添加)
         example_add () {
            this.dTargetRoom = true
         },
         // 一键复制提示语句(复制与匹配)
         dcopy_save_info () {
            if (!this.hotelValExample) {
               this.$alert(this.select_hotel, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.dCopyModel) {
               this.$alert(this.select_examp, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.dExampRoomArr || this.dExampRoomArr.length <= 0) {
               this.$alert(this.select_target_room, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let roomArr = []
            let roomobj = {}
            this.dExampRoomArr.forEach((item, index) => {
               if (item.checked === true) {
                  roomobj.roomId = item.roomId
                  roomobj.roomNo = item.roomNo
                  roomArr.push(roomobj)
                  roomobj = {}
               }
            })
            if (roomArr.length <= 0) {
               this.$alert(this.select_target_room, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            this.MatchingRoomArr = roomArr
            return true
         },
         /**
          * 匹配测试(显示)
          * @param {int} id 匹配
          * @param {int} category 测试
          */
         matching_test (id, category) {
            let _this = this
            this.newType = []
            this.MatRoomArr = []
            this.collapseImg = true
            this.collapseState = true
            if (this.dcopy_save_info()) {
               _this.dMatchingTest = true
               _this.match_state(category)
               _this.eachRooms(0, category)
            }
         },
         eachRooms (index, category) {
            let _this = this
            if (_this.newType.length > 0) {
               _this.collapseImg = false
            }
            if (index >= _this.MatchingRoomArr.length) {
               _this.collapseState = false
               _this.match_state(category)
               return
            }
            let item = this.MatchingRoomArr[index]
            _this.matching_test_list(item.roomId, item.roomNo, index, category)
         },
         /**
          * 匹配测试(显示)
          * @param {String} roomId 房间id
          * @param {String} roomNo 测试
          * @param {int} index 最大长度
          * @param {int} category 判断是匹配还是复制 1为匹配 2为复制
          */
         // 匹配测试列表(显示)
         matching_test_list (roomId, roomNo, index, category) {
            let _this = this
            let url = ''
            let param = {}
            let url1 = urlObj.site + urlObj.sceneMatch
            let url2 = urlObj.site + urlObj.sceneCopyApBy
            let param1 = {
               sceneRoomId: _this.exampleRoomId, // 场景房间id
               targetRoomId: roomId // 目标房间id
            }
            let param2 = {
               templateRoomId: _this.dCopyModel, // 样板房下房间id
               targetRoomId: roomId, // 目标房间id
               targetRoomNo: roomNo // 目标房间编号
            }
            if (category) {
               url = url2
               param = param2
            } else {
               url = url1
               param = param1
            }
            if (category) {
               _this.MatchCategory = false
            } else {
               _this.MatchCategory = true
            }
            this.$axios.post(url, param).then((res) => {
               if (res.msg === 'success') {
                  // console.log(res)
                  let arr = res.sceneList
                  let state = res.state
                  let tempObj = {}
                  for (let i = 0; i < arr.length; i++) {
                     tempObj.fail = false
                     tempObj.dExampName = roomNo // 目标房间名称
                     tempObj.dExampId = roomId // 目标房间id
                     if (category) {
                        if (state) {
                           tempObj.show = true
                        } else {
                           tempObj.show = false
                        }
                        tempObj.category = false
                     } else {
                        tempObj.show = arr[i].matchResult // 设备下场景是否匹配
                        tempObj.category = true
                     }
                     tempObj.copy = arr[i].matchResult
                     tempObj.sceneName = arr[i].sceneName // 模式
                     tempObj.sceneId = arr[i].sceneId // 场景id
                     tempObj.retTemplateConditionList = arr[i].retTemplateConditionList // 触发条件(样板)
                     tempObj.retTemplateTargetList = arr[i].retTemplateTargetList // 执行动作(样板)
                     tempObj.retConditionList = arr[i].retConditionList // 触发条件(目标)
                     tempObj.retTargetList = arr[i].retTargetList // 执行动作(目标)
                     _this.newType.push(tempObj)
                     tempObj = {}
                  }
                  if (state && category) {
                     _this.MatRoomArr.push(index)
                  }
                  _this.eachRooms(index + 1, category)
               } else {
                  _this.$message({
                     showClose: true,
                     message: this.filterStatus(res.msg),
                     type: 'error'
                  })
               }
            }).catch(error => {
               // console.log(error.response.data)
               let tempObj = {}
               _this.MatchState = false
               tempObj.fail = true
               tempObj.dExampName = roomNo // 目标房间名称
               tempObj.sceneName = '————' // 模式
               tempObj.text = error.response.data.msg // 结果
               _this.newType.push(tempObj)
               tempObj = {}
               _this.eachRooms(index + 1, category)
            })
         },
         // 判断全部匹配结果
         match_state (category) {
            let _this = this
            let matState = true
            _this.newType.forEach((item, index) => {
               if (item.show === false || item.fail === true) {
                  matState = false
               }
            })
            if (_this.newType.length <= 0) {
               matState = false
            }
            if (matState) {
               this.MatchState = true
            } else {
               this.MatchState = false
            }
            if (matState && category) {
               this.matchingState = true
            } else {
               this.matchingState = false
            }
         },
         // 匹配测试(修改)
         matching_test_edit (mac) {
            this.matchingMac = mac
            this.dMatchingCatch = true
            this.dLocation_list() // 设备位置
            this.get_device_name(mac)
         },
         // 获取设备详情(mac)
         get_device_name (mac) {
            let detUrl = urlObj.site + urlObj.deviceName
            let detParam = {
               deviceName: mac
            }
            this.$axios.get(detUrl, detParam).then((res) => {
               if (res.msg === 'success') {
                  let arr = res
                  this.dEditFloorVal = this.filterStr(arr.floor) // 楼层
                  this.dEditRoomNoId = this.filterStr(arr.roomId) // 房间id
                  this.dEditRoomNoVal = this.filterStr(arr.roomNo) // 房间号
                  this.dEditLocationVal = this.filterStr(arr.deviceLocation) // 设备位置
                  this.dEditAliasVal = this.filterStrbmer(arr.deviceAlias) // 设备别名
                  this.dEditId = this.filterStr(arr.id) // 设备id
                  this.get_device_num(this.dEditId)
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取设备位数
         get_device_num (devId) {
            let detUrl = urlObj.site + urlObj.getDevDetail
            let detParam = {
               id: devId
            }
            this.$axios.get(detUrl, detParam).then((res) => {
               if (res.msg === 'success') {
                  let arr = res.switchList
                  if (!arr) {
                     this.numFlag = 0
                     this.aliasArrVal = []
                  } else {
                     this.numFlag = arr.length
                     let tempArr = []
                     for (var i = 0; i < arr.length; i++) {
                        tempArr.push(arr[i].switchAlias)
                     }
                     this.aliasArrVal = tempArr // 第几位设备别名
                  }
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 重置(修改设备)
         edit_reset () {
            // this.dEditFloorVal = ''
            // this.dEditRoomNoVal = ''
            this.dEditLocationVal = ''
            this.dEditAliasVal = ''
            this.aliasArrVal = []
         },
         // 保存(修改设备)
         edit_save () {
            // 楼层(修改设备)
            // if (!this.dEditFloorVal) {
            //   this.$alert(this.floor_no_null, '', {
            //     confirmButtonText: this.confirm,
            //     type: 'error'
            //   })
            //   return
            // }
            // 设备位置(修改设备)
            if (!this.dEditLocationVal) {
               this.$alert(this.dev_location_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 房间号(修改设备)
            // if (!this.dEditRoomNoVal) {
            //   this.$alert(this.room_no_null, '', {
            //     confirmButtonText: this.confirm,
            //     type: 'error'
            //   })
            //   return
            // }
            // let ediUrl = ''
            // if (this.dEditGatewayId === 0) {
            //   ediUrl = urlObj.site + urlObj.updateGateway // 网关保存
            // } else {
            //   ediUrl = urlObj.site + urlObj.updateDevice // 设备保存
            // }
            let ediUrl = urlObj.site + urlObj.updateDevice // 设备保存
            let ediParam = {
               id: this.dEditId, // 设备ID
               hotelId: this.hotelValExample, // 所属酒店
               floor: this.dEditFloorVal, // 楼层
               deviceLocation: this.dEditLocationVal, // 设备位置
               roomId: this.dEditRoomNoId, // 房间ID
               roomNo: this.dEditRoomNoVal, // 房间号
               deviceAlias: this.dEditAliasVal // 设备别名
            }
            // if (this.gatewaySync) {
            //   ediParam.isUpdateChildDevice = this.gatewayVal
            // }
            this.$axios.post(ediUrl, ediParam).then((res) => {
               if (res.msg === 'success') {
                  // console.log(res)
                  this.dMatchingCatch = false // 关闭编辑框
                  this.$message({
                     showClose: true,
                     message: this.update_success,
                     type: 'success'
                  })
                  this.edit_save_alias(this.dEditId) // 保存位数别名
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 保存位数别名
         edit_save_alias (devId) {
            let indexStr = ''
            let aliasStr = ''
            for (let i = 0; i < this.aliasArrVal.length; i++) {
               indexStr += i + ','
               if (!this.aliasArrVal[i]) {
                  aliasStr += '' + ','
               } else {
                  aliasStr += this.aliasArrVal[i] + ','
               }
            }
            let ediUrl = urlObj.site + urlObj.updateSwitchAlias // 网关保存
            let ediParam = {
               deviceId: devId, // 设备ID
               index: indexStr, // 位数索引
               alias: aliasStr // 位数别名
            }
            this.$axios.post(ediUrl, ediParam).then((res) => {
               if (res.msg === 'success') {
                  // console.log(res)
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 匹配测试(单个刷新)
         matching_test_refresh (sceneId, roomId, index) {
            let freshUrl = urlObj.site + urlObj.sceneMatchBySceneId
            let freshParam = {
               sceneId: sceneId, // 样板房场景id
               targetRoomId: roomId // 目标房间id
            }
            this.$axios.post(freshUrl, freshParam).then((res) => {
               if (res.msg === 'success') {
                  // console.log(arr)
                  let arr = res
                  let newTypeOne = this.newType[index]
                  newTypeOne.sceneName = arr.sceneName // 模式
                  newTypeOne.sceneId = arr.sceneId // 场景id
                  if (this.MatchCategory) {
                     newTypeOne.show = arr.matchResult
                  } else {
                     newTypeOne.copy = arr.matchResult
                  }
                  newTypeOne.retTemplateConditionList = arr.retTemplateConditionList // 触发条件(样板)
                  newTypeOne.retTemplateTargetList = arr.retTemplateTargetList // 执行动作(样板)
                  newTypeOne.retConditionList = arr.retConditionList // 触发条件(目标)
                  newTypeOne.retTargetList = arr.retTargetList // 执行动作(目标)
                  this.$message({
                     showClose: true,
                     message: this.refresh_success,
                     type: 'success'
                  })
                  this.match_state()
               } else {
                  this.$message({
                     showClose: true,
                     message: this.filterStatus(res.msg),
                     type: 'error'
                  })
               }
            })
         },
         // 新建场景(显示)
         add_scene () {
            this.addScene = true
         },
         // 场景模式编辑--显示(新建场景)
         scene_edit () {
            this.dModelEdit = true
            let devUrl = urlObj.site + urlObj.getExecuteDevice
            let devParam = {}
            this.$axios.post(devUrl, devParam).then((res) => {
               if (res.msg === 'success') {
                  let arr = res
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObj.checked = false
                     tempObj.id = arr[i].id
                     tempObj.dModelCnVal = arr[i].modeName
                     tempObj.dModelEnVal = arr[i].enModeName
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.newModel = tempArr
               } else {
                  this.$message({
                     showClose: true,
                     message: this.filterStatus(res.msg),
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 场景模式编辑--模板(添加)
         add_model () {
            let obj = {
               checked: false,
               id: '',
               dModelCnVal: '',
               dModelEnVal: ''
            }
            this.newModel.push(obj)
         },
         // 场景模式编辑(删除)
         del_model () {
            let arr = []
            for (var i = 0; i < this.newModel.length; i++) {
               if (this.newModel[i].checked === true) {
                  arr.unshift(i)
               }
            }
            if (arr.length > 0) {
               for (var k = 0; k < arr.length; k++) {
                  this.newModel.splice(arr[k], 1)
               }
            } else {
               this.newModel.pop()
            }
         },
         // 场景模式保存(新建场景)
         scene_model_save () {
            let cnStr = ''
            let enStr = ''
            // 判断非空
            for (var i = 0; i < this.newModel.length; i++) {
               if (!this.newModel[i].dModelCnVal) {
                  this.$alert(this.scene_model_no_null, '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
                  return
               }
               cnStr += this.newModel[i].dModelCnVal + '%'
               if (this.newModel[i].dModelEnVal) {
                  enStr += this.newModel[i].dModelEnVal + '%'
               } else {
                  enStr += '—%'
               }
            }
            // 判断模式名称是否相同
            for (var s = 0; s < this.newModel.length; s++) {
               for (var f = s + 1; f < this.newModel.length; f++) {
                  if (this.newModel[s].dModelCnVal === this.newModel[f].dModelCnVal) {
                     this.$alert(this.model_name_no_equals, '', {
                        confirmButtonText: this.confirm,
                        type: 'error'
                     })
                     return
                  }
               }
            }
            let addUrl = urlObj.site + urlObj.addSceneModel
            let addParam = {
               type: 1,
               modes: cnStr,
               enModes: enStr
            }
            this.$axios.post(addUrl, addParam).then((res) => {
               if (res.msg === 'success') {
                  this.newModel = []
                  this.dModelEdit = false
                  this.$message({
                     showClose: true,
                     message: this.add_success,
                     type: 'success'
                  })
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取设备位置
         get_location () {
            let locUrl = urlObj.site + urlObj.getAttrList
            let locParam = {
               dictType: 1
            }
            this.$axios.post(locUrl, locParam).then((res) => {
               if (res.msg === 'success') {
                  // console.log(res)
                  let arr = res
                  if (!res || arr.length <= 0) {
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  let tempArrCn = []
                  let tempObjCn = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObj.locationCode = arr[i].dictCode
                     tempObjCn.value = arr[i].dictValue
                     tempObjCn.label = arr[i].dictValue
                     if (localStorage.lang === 'cn') {
                        tempObj.value = arr[i].dictValue
                        tempObj.label = arr[i].dictValue
                     } else {
                        tempObj.value = arr[i].dictEnValue
                        tempObj.label = arr[i].dictEnValue
                     }
                     tempArr.push(tempObj)
                     tempArrCn.push(tempObjCn)
                     tempObj = {}
                     tempObjCn = {}
                  }
                  this.dTriggerLocationVal = tempArr[0].value
                  this.dExecuteLocationVal = tempArr[0].value
                  this.dTriggerLocationOpt = tempArr // 设备位置(触发设备)
                  this.dExecuteLocationOpt = tempArr // 设备位置(执行设备)
                  this.dTriggerLocationValCn = tempArrCn[0].value
                  this.dExecuteLocationValCn = tempArrCn[0].value
                  this.dTriggerLocationOptCn = tempArrCn
                  this.dExecuteLocationOptCn = tempArrCn
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取设备--触发设备(新建场景)
         get_tigger_add () {
            let devUrl = urlObj.getDeviceRoomId
            let devParam = {
               roomId: this.saveRoomId // 房间ID
               // hotelId: this.saveHotelRoomId // 酒店ID
            }
            this.$axios.get(devUrl, devParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let arr = res.records
                  let tempArr = []
                  let tempObj = {}
                  let tempArrCn = []
                  let tempObjCn = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObjCn.id = tempObj.id = arr[i].id
                     tempObjCn.devCode = tempObj.devCode = arr[i].dictCode
                     tempObjCn.alias = arr[i].dictValue
                     tempObjCn.propArr = arr[i].dictPropertyValue.split(',')
                     if (localStorage.lang === 'cn') {
                        tempObj.alias = arr[i].dictValue
                        tempObj.propArr = arr[i].dictPropertyValue.split(',')
                     } else {
                        tempObj.alias = arr[i].dictEnValue
                        tempObj.propArr = arr[i].dictPropertyEnValue.split(',')
                     }
                     tempObjCn.attrCode = tempObj.attrCode = arr[i].dictProperty.split(',')
                     tempObjCn.checked = tempObj.checked = false
                     tempObjCn.val = tempObj.val = ''
                     tempArr.push(tempObj)
                     tempArrCn.push(tempObjCn)
                     tempObj = {}
                     tempObjCn = {}
                  }
                  this.triggerDevList = tempArr // 新建场景--触发设备--添加--设备列表
                  this.triggerDevListCn = tempArrCn
                  this.executeDevList = tempArr // 新建场景--执行设备--添加--设备列表
                  this.executeDevListCn = tempArrCn
                  console.log(this.triggerDevListCn)
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 触发设备--显示(新建场景)
         tigger_add () {
            this.get_location() // 获取设备位置
            this.get_tigger_add() // 获取设备--触发设备(新建场景)
            // this.triggerDevConfirmArr = [] // 清空触发设备确认
            // this.triggerDevConfirmArrCn = [] // 清空触发Cn设备确认
            this.trigger_add_reset()
            this.dTriggerAdd = true
         },
         // 执行设备--显示(新建场景)
         execute_add () {
            this.get_location() // 获取设备位置
            this.get_tigger_add() // 获取设备--触发设备(新建场景)
            this.execute_add_reset()
            this.dExecuteAdd = true
         },
         // 触发设备选择位置(新建场景)
         triggerChange (value) {
            let findindex = this.dTriggerLocationOpt.findIndex(item => item.value === value)
            this.dTriggerLocationValCn = this.dTriggerLocationOptCn[findindex].value
         },
         // 执行设备选择位置(新建场景)
         executeChange (value) {
            let findindex = this.dExecuteLocationOpt.findIndex(item => item.value === value)
            this.dExecuteLocationValCn = this.dExecuteLocationOptCn[findindex].value
         },
         // 触发设备复选框改变(新建场景)
         triggerCksChange (flag, idx) {
            if (flag === true) {
               this.triggerDevList[idx].val = this.triggerDevList[idx].propArr[0]
               this.triggerDevListCn[idx].val = this.triggerDevListCn[idx].propArr[0]
            } else {
               this.triggerDevList[idx].val = ''
               this.triggerDevListCn[idx].val = ''
            }
         },
         // 执行设备复选框改变(新建场景)
         executeCksChange (flag, idx) {
            if (flag === true) {
               this.executeDevList[idx].val = this.executeDevList[idx].propArr[0]
               this.executeDevListCn[idx].val = this.executeDevListCn[idx].propArr[0]
            } else {
               this.executeDevList[idx].val = ''
               this.executeDevListCn[idx].val = ''
            }
         },
         // 触发设备单选框改变(新建场景)
         triggerRadioChange (idx, index) {
            this.triggerDevList[idx].checked = true
            this.triggerDevListCn[idx].checked = true
            this.triggerDevListCn[idx].val = this.triggerDevListCn[idx].propArr[index]
         },
         // 执行设备单选框改变(新建场景)
         executeRadioChange (idx, index) {
            this.executeDevList[idx].checked = true
            this.executeDevListCn[idx].checked = true
            this.executeDevListCn[idx].val = this.executeDevListCn[idx].propArr[index]
         },
         // 触发设备确认(新建场景)
         trigger_dev_confirm () {
            let tempArr = []
            let tempObj = {}
            let tempArrCn = []
            let tempObjCn = {}
            let aliasArr = []
            let aliasObj = {}
            let locationCode = '' // 位置编码
            let attrCode = '' // 属性编码
            for (var i = 0; i < this.triggerDevList.length; i++) {
               if (this.triggerDevList[i].checked === true) {
                  for (var w = 0; w < this.dTriggerLocationOpt.length; w++) {
                     if (this.dTriggerLocationOpt[w].value === this.dTriggerLocationVal) {
                        locationCode = this.dTriggerLocationOpt[w].locationCode
                     }
                  }
                  for (var q = 0; q < this.triggerDevList[i].propArr.length; q++) {
                     if (this.triggerDevList[i].val === this.triggerDevList[i].propArr[q]) {
                        attrCode = this.triggerDevList[i].attrCode[q]
                     }
                  }
                  tempObj.code = locationCode + ' : ' + this.triggerDevList[i].devCode + ' - ' + attrCode + ' , '
                  tempObj.text = this.dTriggerLocationVal + ' : ' + this.triggerDevList[i].alias + ' - ' + this.triggerDevList[i].val + ',\n'
                  tempObj.alias = this.dTriggerLocationVal + ' : ' + this.triggerDevList[i].alias
                  tempObjCn.code = locationCode + ' : ' + this.triggerDevListCn[i].devCode + ' - ' + attrCode + ' , '
                  tempObjCn.text = this.dTriggerLocationValCn + ' : ' + this.triggerDevListCn[i].alias + ' - ' + this.triggerDevListCn[i].val + ',\n'
                  tempObjCn.alias = this.dTriggerLocationValCn + ' : ' + this.triggerDevListCn[i].alias
                  tempArr.push(tempObj)
                  tempArrCn.push(tempObjCn)
                  tempObj = {}
                  tempObjCn = {}
                  aliasObj.alias = this.dTriggerLocationVal + ' : ' + this.triggerDevList[i].alias
                  aliasArr.push(aliasObj)
                  aliasObj = {}
               }
            }
            // 判断是否相同
            for (var s = 0; s < aliasArr.length; s++) {
               for (var d = 0; d < this.triggerDevConfirmArr.length; d++) {
                  if (aliasArr[s].alias === this.triggerDevConfirmArr[d].alias) {
                     this.$alert(this.eq_location_device_tip, '', {
                        confirmButtonText: this.confirm,
                        type: 'error'
                     })
                     return
                  }
               }
            }
            let concatArr = this.triggerDevConfirmArr.concat(tempArr) // 合并
            this.triggerDevConfirmArr = concatArr
            let concatArrCn = this.triggerDevConfirmArrCn.concat(tempArrCn) // 合并
            this.triggerDevConfirmArrCn = concatArrCn
            for (var k = 0; k < this.triggerDevList.length; k++) {
               this.triggerDevList[k].checked = false
               this.triggerDevListCn[k].checked = false
               this.triggerDevList[k].val = ''
               this.triggerDevListCn[k].val = ''
            }
         },
         // 执行设备确认(新建场景)
         execute_dev_confirm () {
            let tempArr = []
            let tempObj = {}
            let tempArrCn = []
            let tempObjCn = {}
            let aliasArr = []
            let aliasObj = {}
            let locationCode = '' // 位置编码
            let attrCode = '' // 属性编码
            for (var i = 0; i < this.executeDevList.length; i++) {
               if (this.executeDevList[i].checked === true) {
                  for (var w = 0; w < this.dExecuteLocationOpt.length; w++) {
                     if (this.dExecuteLocationOpt[w].value === this.dExecuteLocationVal) {
                        locationCode = this.dExecuteLocationOpt[w].locationCode
                     }
                  }
                  for (var q = 0; q < this.executeDevList[i].propArr.length; q++) {
                     if (this.executeDevList[i].val === this.executeDevList[i].propArr[q]) {
                        attrCode = this.executeDevList[i].attrCode[q]
                     }
                  }
                  tempObj.code = locationCode + ' : ' + this.executeDevList[i].devCode + ' - ' + attrCode + ' , '
                  tempObj.text = this.dExecuteLocationVal + ' : ' + this.executeDevList[i].alias + ' - ' + this.executeDevList[i].val + ',\n'
                  tempObj.alias = this.dExecuteLocationVal + ' : ' + this.executeDevList[i].alias
                  tempObjCn.code = locationCode + ' : ' + this.executeDevListCn[i].devCode + ' - ' + attrCode + ' , '
                  tempObjCn.text = this.dExecuteLocationValCn + ' : ' + this.executeDevListCn[i].alias + ' - ' + this.executeDevListCn[i].val + ',\n'
                  tempObjCn.alias = this.dExecuteLocationValCn + ' : ' + this.executeDevListCn[i].alias
                  tempArr.push(tempObj)
                  tempArrCn.push(tempObjCn)
                  tempObj = {}
                  tempObjCn = {}
                  aliasObj.alias = this.dExecuteLocationVal + ' : ' + this.executeDevList[i].alias
                  aliasArr.push(aliasObj)
                  aliasObj = {}
               }
            }
            // 判断是否相同
            for (var s = 0; s < aliasArr.length; s++) {
               for (var d = 0; d < this.executeDevConfirmArr.length; d++) {
                  if (aliasArr[s].alias === this.executeDevConfirmArr[d].alias) {
                     this.$alert(this.eq_location_device_tip, '', {
                        confirmButtonText: this.confirm,
                        type: 'error'
                     })
                     return
                  }
               }
            }
            let concatArr = this.executeDevConfirmArr.concat(tempArr) // 合并
            this.executeDevConfirmArr = concatArr
            let concatArrCn = this.executeDevConfirmArrCn.concat(tempArrCn) // 合并
            this.executeDevConfirmArrCn = concatArrCn
            console.log(concatArrCn)
            for (var k = 0; k < this.executeDevList.length; k++) {
               this.executeDevList[k].checked = false
               this.executeDevList[k].val = ''
               this.executeDevListCn[k].checked = false
               this.executeDevListCn[k].val = ''
            }
         },
         // 触发设备删除(新建场景)
         trigger_add_del (idx) {
            this.triggerDevConfirmArr.splice(idx, 1)
            this.triggerDevConfirmArrCn.splice(idx, 1)
         },
         // 执行设备删除(新建场景)
         execute_add_del (idx) {
            this.executeDevConfirmArr.splice(idx, 1)
            this.executeDevConfirmArrCn.splice(idx, 1)
         },
         // 触发设备重置(新建场景)
         trigger_add_reset () {
            this.triggerDevConfirmArr = []
            this.triggerDevConfirmArrCn = []
         },
         // 执行设备重置(新建场景)
         execute_add_reset () {
            this.executeDevConfirmArr = []
            this.executeDevConfirmArrCn = []
         },
         // 触发设备保存(新建场景)
         trigger_add_save () {
            if (!this.triggerDevConfirmArr || this.triggerDevConfirmArr.length <= 0) {
               this.$alert(this.confirm_trigger_device, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let str = ''
            let code = ''
            let strCn = ''
            for (var i = 0; i < this.triggerDevConfirmArr.length; i++) {
               str += this.triggerDevConfirmArr[i].text
               code += this.triggerDevConfirmArr[i].code
               strCn += this.triggerDevConfirmArrCn[i].text
            }
            this.dTriggerDevVal = str
            this.dTriggerDevCode = code
            this.dTriggerAdd = false
            this.dTriggerDevValCn = strCn
            console.log(this.dTriggerDevVal)
            console.log(this.dTriggerDevValCn)
         },
         // 执行设备保存(新建场景)
         execute_add_save () {
            if (!this.executeDevConfirmArr || this.executeDevConfirmArr.length <= 0) {
               this.$alert(this.confirm_execute_device, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let str = ''
            let code = ''
            let strCn = ''
            for (var i = 0; i < this.executeDevConfirmArr.length; i++) {
               str += this.executeDevConfirmArr[i].text
               code += this.executeDevConfirmArr[i].code
               strCn += this.executeDevConfirmArrCn[i].text
            }
            this.dExecuteDevVal = str
            this.dExecuteDevCode = code
            this.dExecuteAdd = false
            this.dExecuteDevValCn = strCn
            console.log(this.dExecuteDevValCn)
            // this.dExecuteAdd = false
            // let str = ''
            // for (var i = 0; i < this.executeDevList.length; i++) {
            //   if (this.executeDevList[i].checked === true) {
            //     str += this.dExecuteLocationVal + ' : ' + this.executeDevList[i].alias + ' - ' + this.executeDevList[i].val + ',\n'
            //   }
            // }
            // this.dExecuteDevVal = str
         },
         // 酒店下拉框(表头)改变
         room_change () {
            this.dRoomTypeVal = ''
            this.dFloorVal = ''
            this.get_room_type() // 获取房间类型
            this.get_floor() // 获取楼层
            // this.get_room_list() // 获取房间列表
         },
         // 应用房间--添加
         add_app_room () {
            this.hotelValRoom = this.hotelOpt[0].value
            this.dAppRoom = true
            this.get_room_type() // 获取房间类型
            this.get_floor() // 获取楼层
            this.get_room_list() // 获取房间列表
         },
         // 搜索房间类型(新建场景)
         search_room_type () {
            this.get_room_list() // 获取房间列表
         },
         // 获取房间类型(一键复制)
         get_room_type_example () {
            let rooUrl = urlObj.getHotelRoomTypeList
            let rooParam = {
               hotelId: this.hotelValExample // 酒店ID
            }
            this.$axios.post(rooUrl, rooParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let dataObj = res.records
                  if (!dataObj || dataObj.length <= 0) {
                     this.dExampleTypeOpt = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < dataObj.length; i++) {
                     // tempObj.value = dataObj[i].code
                     tempObj.value = this.filterStr(dataObj[i].roomTypeId)
                     tempObj.label = this.filterStr(dataObj[i].roomTypeName)
                     // if (localStorage.lang === 'cn') {
                     //   tempObj.value = this.filterStr(dataObj[i].id) + ''
                     //   tempObj.label = this.filterStr(dataObj[i].name)
                     // } else {
                     //   tempObj.value = this.filterStr(dataObj[i].id) + ''
                     //   tempObj.label = this.filterStr(dataObj[i].enName)
                     // }
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dExampleTypeOpt = tempArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取房间类型
         get_room_type () {
            let rooUrl = urlObj.getHotelRoomTypeList
            let rooParam = {
               hotelId: this.hotelValRoom // 酒店ID
            }
            this.$axios.post(rooUrl, rooParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let dataObj = res.records
                  if (!dataObj || dataObj.length <= 0) {
                     this.dRoomTypeOpt = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < dataObj.length; i++) {
                     // tempObj.value = dataObj[i].code\
                     tempObj.value = this.filterStr(dataObj[i].roomTypeId)
                     tempObj.label = this.filterStr(dataObj[i].roomTypeName)
                     // if (localStorage.lang === 'cn') {
                     //   tempObj.value = this.filterStr(dataObj[i].code)
                     //   tempObj.label = this.filterStr(dataObj[i].name)
                     // } else {
                     //   tempObj.value = this.filterStr(dataObj[i].code)
                     //   tempObj.label = this.filterStr(dataObj[i].enName)
                     // }
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dRoomTypeOpt = tempArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取楼层
         get_floor () {
            let floUrl = urlObj.hotelDetail + `/${this.hotelValRoom}`
            let floParam = {
               // hotelId: this.hotelValRoom // 酒店ID
            }
            this.$axios.get(floUrl, floParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let data = res.records
                  let beginnum = Number(data.beginFloor)
                  let num = Number(data.endFloor)
                  if (!num || num <= 0) {
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < num; i++) {
                     tempObj.value = i + 1
                     tempObj.label = i + 1
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dFloorOpt = tempArr // 楼层(新建场景)
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取楼层(复制)
         get_floor_Matching () {
            let floUrl = urlObj.hotelDetail + `/${this.hotelValRoom}`
            let floParam = {
               // hotelId: this.hotelValRoom // 酒店ID
            }
            this.$axios.get(floUrl, floParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let data = res.records
                  let beginnum = Number(data.beginFloor)
                  let num = Number(data.endFloor)
                  if (!num || num <= 0) {
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < num; i++) {
                     tempObj.value = i + 1
                     tempObj.label = i + 1
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  // console.log(tempArr)
                  this.dEditFloorOpt = tempArr // 楼层
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 单选
         all_radio (val) {
            this.dAppRoomListName = val
            // for (var i = 0; i < this.dAppRoomList.length; i++) {
            //   this.dAppRoomList[i].checked = check
            // }
         },
         // 全选
         all_select (check) {
            for (var i = 0; i < this.dAppRoomList.length; i++) {
               this.dAppRoomList[i].checked = check
            }
         },
         // 全选
         all_select_copy (check) {
            for (var i = 0; i < this.dCopyRoomArr.length; i++) {
               this.dCopyRoomArr[i].checked = check
            }
         },
         // 获取房间列表
         get_room_list () {
            let rooUrl = urlObj.getRoomLists
            let rooParam = {
               hotelId: this.hotelValRoom, // 酒店ID
               roomTypeId: this.dRoomTypeVal,
               floor: this.dFloorVal
            }
            // let rooUrl = urlObj.site + urlObj.getCopyRoom
            // let rooParam = {
            //   hotelId: this.hotelValRoom, // 酒店ID
            //   roomId: this.dCopyRoomId, // 房间ID
            //   roomTypeCode: this.dRoomTypeVal,
            //   floor: this.dFloorVal,
            //   // index: this.conditionIndex,
            //   deviceNames: this.conditionDeviceName
            // }
            this.$axios.post(rooUrl, rooParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let arr = res.records
                  if (!arr || arr.length <= 0) {
                     this.dAppRoomList = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObj.roomId = arr[i].id
                     tempObj.checked = false
                     tempObj.roomNo = arr[i].roomNo
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dAppRoomListId = tempArr[0].roomId
                  this.dAppRoomListName = tempArr[0].roomNo
                  this.dAppRoomList = tempArr
                  // // 房间号(复制场景)
                  // let tempRoomArr = []
                  // for (var k = 0; k < tempArr.length; k++) {
                  //   if (roomId !== tempArr[k].roomId) {
                  //     tempRoomArr.push(tempArr[k])
                  //   }
                  // }
                  // this.dCopyRoomArr = tempRoomArr
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               this.dAppRoomList = []
               // this.dCopyRoomArr = []
               console.log(error)
            })
         },
         // 重置(添加应用房间)
         add_room_reset () {
            this.dRoomTypeVal = ''
            this.dFloorVal = ''
         },
         // 应用房间保存(新建场景)
         room_add_save () {
            // if (!this.dAppRoomList || this.dAppRoomList.length <= 0) {
            //   this.$alert(this.select_room, '', {
            //     confirmButtonText: this.confirm,
            //     type: 'error'
            //   })
            //   return
            // }
            if (!this.dAppRoomListId) {
               this.$alert(this.select_room, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // let roomId = ''
            // let roomStr = ''
            // for (var i = 0; i < this.dAppRoomList.length; i++) {
            //   if (this.dAppRoomList[i].checked === true) {
            //     roomId += this.dAppRoomList[i].roomId + '%'
            //     roomStr += this.dAppRoomList[i].roomNo + ';'
            //   }
            // }
            // this.dAppRoomId = roomId
            this.dAppRoomVal = this.dAppRoomListName
            // if (this.dAppRoomVal.length <= 0) {
            //   this.$alert(this.select_room, '', {
            //     confirmButtonText: this.confirm,
            //     type: 'error'
            //   })
            //   return
            // }
            this.saveRoomId = this.dAppRoomListId
            this.saveHotelRoomId = this.hotelValRoom
            // this.addAllSelect = false // 全选
            this.dAppRoom = false
         },
         // 是否生效状态改变
         checkChange (check) {
            this.dSwitchVal = check
         },
         // 新建场景--重置
         add_scene_reset () {
            this.dSceneModel = ''
            this.dTriggerDevVal = ''
            this.dExecuteDevVal = ''
            this.dAppRoomVal = ''
            this.dSwitchVal = true
            this.dSceneTimeVal = '0'
            this.dSceneDelayVal = '0'
            this.dRemarkVal = ''
         },
         // 新建场景--保存
         add_scene_save () {
            let sceneNameText = ''
            for (var i = 0; i < this.dSceneModelOpt.length; i++) {
               if (this.dSceneModel === this.dSceneModelOpt[i].value) {
                  sceneNameText = this.dSceneModelOpt[i].label
               }
            }
            if (!sceneNameText || sceneNameText.length <= 0) {
               this.$alert(this.scene_model_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.dTriggerDevVal || this.dTriggerDevVal.length <= 0) {
               this.$alert(this.trigger_device_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.dExecuteDevVal || this.dExecuteDevVal.length <= 0) {
               this.$alert(this.execute_device_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.dAppRoomVal || this.dAppRoomVal.length <= 0) {
               this.$alert(this.app_room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (this.dRemarkVal.length >= 300) {
               this.$alert(this.remark_len_no_300, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let addUrl = urlObj.site + urlObj.addApScene
            let addParam = {
               hotelId: this.saveHotelRoomId, // 添加应用房间的酒店id
               sceneType: this.dSceneModel, // 场景模式ID
               sceneName: sceneNameText, // 场景模式名称
               // sceneCondition: this.dTriggerDevVal, // 触发设备
               sceneCondition: this.dTriggerDevValCn, // 触发设备
               sceneConditionCode: this.dTriggerDevCode, // 触发设备编码(床头码：设备别名码 - 设备属性码 ,)
               // sceneTouch: this.dExecuteDevVal, // 执行设备
               sceneTouch: this.dExecuteDevValCn, // 执行设备
               sceneTouchCode: this.dExecuteDevCode, // 触发设备编码(床头码：设备别名码 - 设备属性码 ,)
               roomNo: this.dAppRoomVal, // 应用房间号
               roomId: this.dAppRoomId, // 应用房间号ID
               sceneState: this.dSwitchVal === true ? 1 : 0, // 是否生效(0:关闭状态，1:打开状态)
               closeSceneTime: this.dSceneTimeVal === '0' ? '' : this.dSceneTimeVal, // 场景关闭时间
               delayedSceneTime: this.dSceneDelayVal === '0' ? '' : this.dSceneDelayVal, // 场景关闭时间
               remark: this.dRemarkVal // 备注信息
            }
            this.$axios.post(addUrl, addParam).then((res) => {
               if (res.msg === 'success') {
                  this.$message({
                     showClose: true,
                     message: this.add_success,
                     type: 'success'
                  })
                  this.addScene = false
                  this.getSceneList() // 场景列表
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
               this.add_scene_reset() // 重置(添加场景)
            }).catch(error => {
               console.log(error)
            })
         },
         // 场景状态改变 + 锁定状态改变
         scene_state_change (sceneId, state, id) {
            // console.log(sceneId, state, id)
            let staUrl = ''
            let staParam = {}
            let staUrl1 = urlObj.switchLocked
            let staUrl2 = urlObj.sceneState
            let staParam1 = {
               sceneId: sceneId,
               locked: state
            }
            let staParam2 = {
               sceneId: sceneId,
               state: state
            }
            if (id === 1) {
               staUrl = staUrl1
               staParam = staParam1
            } else {
               staUrl = staUrl2
               staParam = staParam2
            }
            this.$axios.get(staUrl, staParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  this.$message({
                     showClose: true,
                     message: this.state_change_succss,
                     type: 'success'
                  })
                  this.getSceneList() // 场景列表
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 详情
         detail (row) {
            this.dDetTriggerConVal = []
            let detUrl = urlObj.sceneDetail
            let detParam = {
               sceneId: row.scene_id
            }
            this.$axios.get(detUrl, detParam).then((res) => {
               if (res.success) {
                  this.dialogDetail = true
                  // console.log(res)
                  let obj = res.records
                  this.dDetHotelVal = this.filterStr(obj.hotelName) // 所属酒店(详情)
                  // 状态(详情)
                  // this.dDetStatusVal = this.filterStr(obj.sceneState) // 状态(详情)
                  if (obj.state === 'VALID') {
                     this.dDetStatusVal = this.effective
                  } else {
                     this.dDetStatusVal = this.invalid
                  }
                  this.dDetRoomVal = this.filterStr(obj.roomNo) // 应用房间(详情)
                  // this.dDetDeviceVal = this.filterStr(obj.) // 执行设备(详情)
                  // this.dDetConditionVal = this.filterStr(obj.) // 执行条件(详情)
                  // 创建类型(详情)
                  // this.dDetTypeVal = this.filterStr(obj.createType) // 创建类型(详情)
                  if (obj.createType === 2) {
                     this.dDetTypeVal = this.pc_side
                  } else if (obj.createType === 3) {
                     this.dDetTypeVal = this.app_side
                  } else {
                     this.dDetTypeVal = '—'
                  }
                  this.dDetModelVal = this.filterStr(obj.sceneName) // 场景模式(详情)
                  if (obj.reverseExecuteTime) {
                     this.dDetTimeVal = obj.reverseExecuteTime // 场景关闭时间(详情)
                  } else {
                     this.dDetTimeVal = this.filterStr(obj.reverseExecuteTime) // 场景关闭时间(详情)
                  }
                  if (obj.delayExecuteTime) {
                     this.dDetDelayVal = obj.delayExecuteTime // 场景延时时间(详情)
                  } else {
                     this.dDetDelayVal = this.filterStr(obj.delayExecuteTime) // 场景延时时间(详情)
                  }
                  this.dDetAddTimeVal = this.filterStr(obj.createTime) // 添加时间(详情)
                  // let triggerCode = this.filterStr(obj.conditionDeviceName)
                  // let triggerName = ''
                  // if (localStorage.lang === 'cn') {
                  //   triggerName = this.filterStr(obj.sceneCondition)
                  // } else {
                  //   triggerName = this.filterStr(obj.sceneEnCondition)
                  // }
                  if (!obj.triggerDeviceList || obj.triggerDeviceList.length <= 0) {
                     this.dDetTriggerConVal = [] // 触发设备(详情)
                  } else {
                     // let triggerCode = obj.conditionDeviceName.split(',')
                     // let triggerName = ''
                     // if (localStorage.lang === 'cn') {
                     //   triggerName = obj.sceneCondition.split(',')
                     // } else {
                     //   triggerName = obj.sceneEnCondition.split(',')
                     // }
                     // if (triggerCode.length !== triggerName.length) {
                     //   triggerCode.push('')
                     // }
                     let tempTriggerArr = []
                     let tempTriggerObj = {}
                     for (var k = 0; k < obj.triggerDeviceList.length; k++) {
                        // tempTriggerObj.triggerText = this.filterStr(triggerCode[k]) + ' —> ' + this.filterStr(triggerName[k])
                        tempTriggerObj.deviceName = this.filterStr(obj.triggerDeviceList[k].deviceName)
                        tempTriggerObj.location = this.filterStr(obj.triggerDeviceList[k].location)
                        tempTriggerObj.versionsName = this.filterStr(obj.triggerDeviceList[k].versionsName)
                        tempTriggerObj.alias = this.filterStr(obj.triggerDeviceList[k].alias)
                        tempTriggerArr.push(tempTriggerObj)
                        tempTriggerObj = {}
                     }
                     this.dDetTriggerConVal = tempTriggerArr // 触发设备(详情)
                  }
                  if (!obj.targetDeviceList || obj.targetDeviceList.length <= 0) {
                     // '未匹配到具体设备！'
                     this.dDetExecuteDevVal = [] // 被执行设备(详情)
                  } else {
                     let tempExecuteArr = []
                     let tempExecuteObj = {}
                     for (var i = 0; i < obj.targetDeviceList.length; i++) {
                        tempExecuteObj.deviceName = obj.targetDeviceList[i].deviceName
                        tempExecuteObj.location = obj.targetDeviceList[i].location
                        tempExecuteObj.versionsName = obj.targetDeviceList[i].versionsName
                        tempExecuteObj.alias = obj.targetDeviceList[i].alias
                        tempExecuteArr.push(tempExecuteObj)
                        tempExecuteObj = {}
                     }
                     this.dDetExecuteDevVal = tempExecuteArr // 被执行设备(详情)
                     console.log(this.dDetExecuteDevVal)
                  }
                  this.dDetRemarkVal = obj.remark
                  this.dDetSyncDeviceVal = obj.sceneSyncList
                  this.dDetLockedVal = obj.locked
                  this.dDetSyncStateVal = obj.syncGatewayState
                  this.dDetSceneActionVal = obj.sceneAction
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 删除
         remove (row) {
            this.$confirm(this.confirm_remove_scene, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(async () => {
               let delUrl = urlObj.delScene
               let delParam = {
                  sceneId: row.scene_id // 场景ID
               }
               this.$axios.get(delUrl, delParam).then((res) => {
                  if (res.success) {
                     // console.log(res)
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getSceneList() // 场景列表
                  }
               }).catch(error => {
                  console.log(error)
               })
            }).catch(cancel => {
               // console.log(cancel)
            })
         },
         // 同步网关
         gateway (row) {
            console.log(row.scene_id)
            let syncUrl = urlObj.SyncGateway
            let syncParam = {
               sceneId: row.scene_id // 场景ID
            }
            this.$axios.get(syncUrl, syncParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  this.$message({
                     showClose: true,
                     message: this.sync_gateway_success,
                     type: 'success'
                  })
                  this.getSceneList() // 场景列表
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 批量信息
         all_info (id) {
            if (this.multipleSelection.length <= 0) {
               let allInfos = ''
               if (id === 3) {
                  allInfos = this.select_remove_scene
               } else if (id === 1) {
                  allInfos = this.select_lock_scene
               } else if (id === 2) {
                  allInfos = this.select_unlock_scene
               }
               this.$alert(allInfos, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            return true
         },
         // 批量删除
         all_delete (row) {
            if (this.all_info(3)) {
               this.$confirm(this.confirm_remove_all, this.prompt, {
                  confirmButtonText: this.confirm,
                  cancelButtonText: this.cancel,
                  type: 'error'
               }).then(async () => {
                  let sceneStr = ''
                  this.multipleSelection.forEach((item, index) => {
                     sceneStr += item.scene_id + ','
                  })
                  let delUrl = urlObj.deleteBatchScene
                  let delParam = {
                     sceneIds: sceneStr // 场景ID
                  }
                  this.$axios.get(delUrl, delParam).then((res) => {
                     if (res.success) {
                        // console.log(res)
                        this.$message({
                           showClose: true,
                           message: this.remove_success,
                           type: 'success'
                        })
                        this.getSceneList()
                     }
                  }).catch(error => {
                     console.log(error)
                  })
               }).catch(cancel => {
                  // console.log(cancel)
               })
            }
         },
         // 批量锁定与解锁
         all_locking (state) {
            let id = state ? 1 : 2
            if (this.all_info(id)) {
               this.$confirm(state ? this.confirm_lock : this.confirm_unlock, this.prompt, {
                  confirmButtonText: this.confirm,
                  cancelButtonText: this.cancel,
                  type: 'error'
               }).then(async () => {
                  let sceneStr = ''
                  // let stateStr = ''
                  this.multipleSelection.forEach((item, index) => {
                     sceneStr += item.scene_id + ','
                     // stateStr += state + ','
                  })
                  let LockUrl = urlObj.sceneBatchLock
                  let LockParam = {
                     sceneIds: sceneStr, // 场景ID
                     locked: state ? 'LOCKED' : 'UNLOCK' // 是否锁定，true为锁定，false为解锁
                  }
                  this.$axios.get(LockUrl, LockParam).then((res) => {
                     if (res.success) {
                        // console.log(res)
                        this.$message({
                           showClose: true,
                           message: this.state_change_succss,
                           type: 'success'
                        })
                        this.getSceneList()
                     }
                  }).catch(error => {
                     console.log(error)
                  })
               }).catch(cancel => {
                  // console.log(cancel)
               })
            }
         },
         // 编辑(显示)
         edit (row) {
            this.dEditTypeCode = row.create_code // 创建类型编码
            this.dEditRoomId = row.room_id // 房间ID
            this.dEditSceneId = row.scene_id // 场景ID
            this.dEditHotelId = row.hotel_id // 酒店ID
            this.dEditSceneType = row.scene_type // 场景类型
            this.dEditSceneModelOpt = []
            if (row.create_code === 3) {
               // App端
               this.dEditSceneModelOpt = this.appModelOpt
            } else {
               // PC端
               this.dEditSceneModelOpt = this.dSceneModelOpt
            }
            let ediUrl = urlObj.site + urlObj.sceneDetail
            let ediParam = {
               sceneId: row.scene_id // 场景ID
            }
            this.$axios.get(ediUrl, ediParam).then((res) => {
               if (res.msg === 'success') {
                  this.dialogEdit = true
                  let obj = res
                  this.editSaveTriggerCodePropertyStr = obj.sceneConditionCode // 编码、属性(字符串--触发设备)
                  this.editSaveTriggerIndexStr = obj.conditionIndex // 位数索引--触发设备
                  this.dEditSceneModel = obj.sceneType + '' // 模式
                  // ---触发设备------------------------------------------------------------------------
                  if (!obj.sceneCondition) {
                     this.dEditTriggerDevVal = this.no_device
                  } else {
                     this.editSaveTriggerCodeStr = obj.conditionDeviceName // 编码(字符串--触发设备)
                     let triggerTextArr = ''
                     if (localStorage.lang === 'cn') {
                        this.editSaveTriggerDevStr = obj.sceneCondition // 位置、名称、别名、属性(字符串--触发设备)
                        triggerTextArr = obj.sceneCondition.split(',')
                     } else {
                        this.editSaveTriggerDevStr = obj.sceneEnCondition // 位置、名称、别名、属性(字符串--触发设备)--英文
                        triggerTextArr = obj.sceneEnCondition.split(',')
                     }
                     let triggerCodeArr = obj.conditionDeviceName.split(',')
                     let triggerIndexArr = obj.conditionIndex.split(',')
                     this.tempTriggerCodeArr = triggerCodeArr // 保存code码
                     let triggerCodeIdxArr = []
                     let triggerStr = ''
                     let triggerArr = []
                     let tIdx = 0
                     for (var i = 0; i < triggerCodeArr.length; i++) {
                        triggerCodeIdxArr.push(triggerCodeArr[i] + ',' + triggerIndexArr[i])
                        triggerStr += triggerCodeArr[i] + ' —>' + triggerTextArr[i] + '\n'
                        tIdx = triggerTextArr[i].indexOf('【')
                        triggerArr.push(triggerCodeArr[i] + triggerTextArr[i].substring(0, tIdx))
                     }
                     this.tempTriggerArr = triggerCodeIdxArr // 触发设备MAC码、索引(用于选中)
                     this.dEditTriggerDevVal = triggerStr // 触发设备
                  }
                  // ---执行设备------------------------------------------------------------------------
                  this.editSaveExecuteCodePropertyStr = obj.sceneTouchCode // 编码、属性(字符串--执行设备)
                  this.editSaveExecuteIndexStr = obj.triggerIndex // 位数索引--执行设备
                  if (!obj.sceneTouch) {
                     this.dEditExecuteDevVal = this.no_device // 被执行设备
                  } else {
                     let DeviceLength = obj.triggerDeviceName.length
                     if (obj.triggerDeviceName.substring(DeviceLength - 1, DeviceLength) === ',') {
                        obj.triggerDeviceName = obj.triggerDeviceName.substring(0, DeviceLength - 1)
                     }
                     this.editSaveExecuteCodeStr = obj.triggerDeviceName // 编码(字符串--执行设备)
                     let executeTextArr = ''
                     if (localStorage.lang === 'cn') {
                        this.editSaveExecuteDevStr = obj.sceneTouch // 位置、名称、别名、属性(字符串--执行设备)
                        executeTextArr = obj.sceneTouch.split(',')
                     } else {
                        this.editSaveExecuteDevStr = obj.sceneEnTouch // 位置、名称、别名、属性(字符串--执行设备)--英文
                        executeTextArr = obj.sceneEnTouch.split(',')
                     }
                     let executeCodeArr = obj.triggerDeviceName.split(',')
                     let executeIndexArr = obj.triggerIndex.split(',')
                     this.tempExecuteCodeArr = executeCodeArr // 保存code码
                     let executeCodeIdxArr = []
                     let executeStr = ''
                     let executeArr = []
                     let eIdx = 0
                     for (var k = 0; k < executeCodeArr.length; k++) {
                        executeCodeIdxArr.push(executeCodeArr[k] + ',' + executeIndexArr[k])
                        executeStr += executeCodeArr[k] + ' —>' + executeTextArr[k] + '\n'
                        eIdx = executeTextArr[k].indexOf('【')
                        executeArr.push(executeCodeArr[k] + executeTextArr[k].substring(0, eIdx))
                     }
                     this.tempExecuteArr = executeCodeIdxArr // 执行设备MAC码、索引(用于选中)
                     this.dEditExecuteDevVal = executeStr // 被执行设备
                  }
                  this.dEditSwitchVal = obj.sceneState // 是否生效
                  this.dEditAppRoomVal = obj.roomNo // 应用房间
                  if (!obj.closeSceneTime) {
                     this.dEditSceneTimeVal = '0' // 场景关闭时间
                  } else {
                     this.dEditSceneTimeVal = obj.closeSceneTime + '' // 场景关闭时间
                  }
                  if (!obj.delayedSceneTime) {
                     this.dEditSceneDelayedVal = '0' // 场景延时时间
                  } else {
                     this.dEditSceneDelayedVal = obj.delayedSceneTime + '' // 场景延时时间
                  }
                  this.dEditRemarkVal = obj.remark // 备注信息
                  // console.log(res)
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 编辑场景--重置
         edit_scene_reset () {
            this.dEditSceneModel = ''
            this.dEditTriggerDevVal = ''
            this.dEditExecuteDevVal = ''
            this.dEditSwitchVal = false
            this.dEditSceneTimeVal = '0'
            this.dEditSceneDelayedVal = '0'
            this.dEditRemarkVal = ''
         },
         // 编辑(保存)
         edit_scene_save () {
            let addUrl = ''
            if (this.dEditTypeCode === 2) {
               // PC端
               addUrl = urlObj.site + urlObj.modifyScene
            } else {
               // APP端
               addUrl = urlObj.site + urlObj.modifyAppScene
            }
            let addParam = {
               id: this.dEditSceneId, // 场景ID
               hotelId: this.dEditHotelId, // 酒店ID
               sceneType: this.dEditSceneType, // 模式类型
               sceneCondition: this.editSaveTriggerDevStr, // 位置、名称、别名、属性(字符串--触发设备)
               sceneTouch: this.editSaveExecuteDevStr, // 位置、名称、别名、属性(字符串--执行设备),
               sceneConditionCode: this.editSaveTriggerCodePropertyStr, // 编码、属性(字符串--触发设备)
               sceneTouchCode: this.editSaveExecuteCodePropertyStr, // 编码、属性(字符串--执行设备)
               conditionDeviceName: this.editSaveTriggerCodeStr, // 编码(字符串--触发设备)
               triggerDeviceName: this.editSaveExecuteCodeStr, // 编码(字符串--执行设备)
               conditionIndex: this.editSaveTriggerIndexStr, // 位数索引--触发设备
               triggerIndex: this.editSaveExecuteIndexStr, // 位数索引--执行设备
               roomId: this.dEditRoomId, // 房间ID
               sceneState: this.dEditSwitchVal === true ? 1 : 0, // 是否生效
               closeSceneTime: this.dEditSceneTimeVal, // 场景关闭时间
               delayedSceneTime: this.dEditSceneDelayedVal, // 场景延时时间
               remark: this.dEditRemarkVal // 备注信息
            }
            this.$axios.post(addUrl, addParam).then((res) => {
               if (res.msg === 'success') {
                  this.getSceneList()
                  this.dialogEdit = false
                  this.$message({
                     showClose: true,
                     message: this.update_success,
                     type: 'success'
                  })
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 编辑场景--触发设备--编辑(编辑场景)
         trigger_edit () {
            this.dTriggerEdit = true
            this.get_trigger_dev_list(1) // 获取触发设备列表--编辑场景
         },
         // 编辑场景--执行设备--编辑(编辑场景)
         execute_edit () {
            this.dExecuteEdit = true
            this.get_trigger_dev_list(2) // 获取触发设备列表--编辑场景
         },
         // 获取触发、执行设备列表--编辑场景
         get_trigger_dev_list (type) {
            this.dEditTriggerDevList = []
            this.dEditExecuteDevList = []
            this.dEditDeviceAttr = []
            this.triggerRadio = '00'
            this.executeRadio = '00'
            let detUrl = urlObj.site + urlObj.getTriggerDevList
            let detParam = {
               roomId: this.dEditRoomId
            }
            this.$axios.get(detUrl, detParam).then((res) => {
               if (res.msg === 'success') {
                  // console.log(res)
                  let arr = res
                  if (!arr || arr.length <= 0) {
                     this.noDevTip = true // 提示标记
                     return
                  }
                  this.noDevTip = false // 提示标记
                  let tempArr = []
                  let tempObj = {}
                  let num = 0
                  let seat = ''
                  for (var i = 0; i < arr.length; i++) {
                     // if (arr[i].deviceNumber.substring(0, 12) === 'HOS-SW-Pro2-') { // 判断是否是开关
                     // num = arr[i].deviceNumber.substring(12, 13)
                     if (arr[i].deviceNumber.substring(0, 10) === 'HOS-SW-Pro' || arr[i].deviceNumber.substring(0, 10) === 'HOS-SC-Pro') { // 判断是否有位数
                        num = arr[i].productName.substring(7, 8)
                        if (num >= 1) {
                           for (var k = 0; k < num; k++) {
                              tempObj.id = arr[i].id // 设备ID
                              tempObj.devCode = arr[i].deviceName // 设备编码
                              tempObj.seatIdx = k + '' // 设备位数索引
                              tempObj.checked = false // 复选框
                              if (!arr[i].deviceLocation) {
                                 // tempObj.devLocation = this.unknown_location // 设备位置
                                 tempObj.devLocation = '—' // 设备位置
                              } else {
                                 tempObj.devLocation = arr[i].deviceLocation // 设备位置
                              }
                              if (k === 0) {
                                 seat = this.seat1 // 第一位
                              } else if (k === 1) {
                                 seat = this.seat2 // 第二位
                              } else if (k === 2) {
                                 seat = this.seat3 // 第三位
                              } else if (k === 3) {
                                 seat = this.seat4 // 第四位
                              } else if (k === 4) {
                                 seat = this.seat5 // 第五位
                              } else {
                                 seat = this.seat6 // 第六位
                              }
                              if (localStorage.lang === 'cn') {
                                 tempObj.devName = arr[i].deviceType + seat // 设备名称
                              } else {
                                 tempObj.devName = arr[i].deviceEnType + seat // 设备名称--英文
                              }
                              if (!arr[i].deviceAlias) {
                                 tempObj.devAlias = '—' // 设备别名
                              } else {
                                 tempObj.devAlias = arr[i].deviceAlias // 设备别名
                              }
                              tempArr.push(tempObj)
                              tempObj = {}
                              seat = ''
                           }
                        }
                     } else {
                        tempObj.seatIdx = '0' // 设备位数索引
                        tempObj.id = arr[i].id // 设备ID
                        tempObj.devCode = arr[i].deviceName // 设备编码
                        tempObj.checked = false // 复选框
                        if (!arr[i].deviceLocation) {
                           // tempObj.devLocation = this.unknown_location // 设备位置
                           tempObj.devLocation = '—' // 设备位置
                        } else {
                           tempObj.devLocation = arr[i].deviceLocation // 设备位置
                        }
                        if (localStorage.lang === 'cn') {
                           tempObj.devName = arr[i].deviceType + seat // 设备名称
                        } else {
                           tempObj.devName = arr[i].deviceEnType + seat // 设备名称--英文
                        }
                        if (!arr[i].deviceAlias) {
                           tempObj.devAlias = '—' // 设备别名
                        } else {
                           tempObj.devAlias = arr[i].deviceAlias // 设备别名
                        }
                        tempArr.push(tempObj)
                        tempObj = {}
                     }
                  }
                  if (type === 1) {
                     this.dEditTriggerDevList = tempArr // 触发设备
                     // this.editSaveTriggerIndexStr = saveTriggerIndexStr // 位数索引(用于保存)
                     let tStr = ''
                     // 选中--编辑--触发设备
                     for (var g = 0; g < this.tempTriggerArr.length; g++) {
                        for (var f = 0; f < this.dEditTriggerDevList.length; f++) {
                           tStr = this.dEditTriggerDevList[f].devCode + ',' + this.dEditTriggerDevList[f].seatIdx
                           if (this.tempTriggerArr[g] === tStr) {
                              this.dEditTriggerDevList[f].checked = true
                           }
                        }
                     }
                  } else {
                     this.dEditExecuteDevList = tempArr // 执行设备
                     let eStr = ''
                     // 选中--编辑--被执行设备
                     for (var h = 0; h < this.tempExecuteArr.length; h++) {
                        for (var j = 0; j < this.dEditExecuteDevList.length; j++) {
                           eStr = this.dEditExecuteDevList[j].devCode + ',' + this.dEditExecuteDevList[j].seatIdx
                           if (this.tempExecuteArr[h] === eStr) {
                              this.dEditExecuteDevList[j].checked = true
                           }
                        }
                     }
                  }
                  this.get_trigger_dev_attr() // 获取触发设备属性--编辑场景
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // // 获取设备列表--编辑场景
         // get_execute_dev_list () {},
         // 获取(触发、执行)设备属性--编辑场景
         get_trigger_dev_attr () {
            let attUrl = urlObj.site + urlObj.getAttrList
            let attParam = {
               dictType: 3
            }
            this.$axios.post(attUrl, attParam).then((res) => {
               if (res.msg === 'success') {
                  // console.log(res)
                  let arr = res
                  if (!res || arr.length <= 0) {
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObj.id = arr[i].id
                     tempObj.label = arr[i].id + ''
                     if (localStorage.lang === 'cn') {
                        tempObj.attrName = arr[i].dictValue
                     } else {
                        tempObj.attrName = arr[i].dictEnValue
                     }
                     tempObj.code = arr[i].dictCode
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dEditDeviceAttr = tempArr
               } else {
                  this.$alert(this.filterStatus(res.msg), '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 编辑场景--触发设备--添加--确认触发设备及属性--重置
         edit_trigger_add_reset () {
            this.editTriggerDevConfirmArr = []
         },
         // 编辑场景--执行设备--添加--确认执行设备及属性--重置
         edit_execute_add_reset () {
            this.editExecuteDevConfirmArr = []
         },
         // 添加触发设备--编辑场景
         edit_trigger_dev_add () {
            let radio = ''
            for (var k = 0; k < this.dEditDeviceAttr.length; k++) {
               if (this.triggerRadio === this.dEditDeviceAttr[k].label) {
                  radio = this.dEditDeviceAttr[k].attrName
               }
            }
            let arr = this.dEditTriggerDevList
            let tempArr = []
            let macArr = []
            let tempObj = {}
            let triggerStr = ''
            let saveTriggerDevStr = '' // 位置、名称、别名、属性(用于保存)
            let saveTriggerCodePropertyStr = '' // 编码、属性(用于保存)
            let saveTriggerCodeStr = '' // 编码(用于保存)
            let saveTriggerIndexStr = '' // 位数索引(用于保存)
            let flag = false
            for (var i = 0; i < arr.length; i++) {
               if (arr[i].checked === true) {
                  flag = true
                  macArr.push(arr[i].devCode + '—>')
                  tempObj.code = arr[i].devCode + '—>'
                  tempObj.location = arr[i].devLocation + ' : '
                  tempObj.text = arr[i].devName
                  tempObj.alias = '【' + arr[i].devAlias + '】'
                  tempObj.property = ' - ' + radio + '\n'
                  tempArr.push(tempObj)
                  tempObj = {}
                  triggerStr += arr[i].devCode + '—>' + arr[i].devLocation + ' : ' + arr[i].devName + '【' + arr[i].devAlias + '】' + ' - ' + radio + '\n'
                  saveTriggerDevStr += arr[i].devLocation + ' : ' + arr[i].devName + '【' + arr[i].devAlias + '】' + ' - ' + radio + ','
                  saveTriggerCodePropertyStr += arr[i].devCode + ' - ' + radio + ','
                  saveTriggerCodeStr += arr[i].devCode + ','
                  saveTriggerIndexStr += arr[i].seatIdx + ','
               }
            }
            // 判断复选框
            if (flag === false) {
               this.$alert(this.sel_trigger_device, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 判断单选框
            if (this.triggerRadio === '00') {
               this.$alert(this.sel_dev_property, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 判断是否存在
            let confrimArr = this.editTriggerDevConfirmArr
            let tStr = ''
            let cStr = ''
            // 判断是否存在
            for (var s = 0; s < confrimArr.length; s++) {
               cStr = confrimArr[s].code + confrimArr[s].location + confrimArr[s].text + confrimArr[s].alias
               for (var d = 0; d < tempArr.length; d++) {
                  tStr = tempArr[d].code + tempArr[d].location + tempArr[d].text + tempArr[d].alias
                  if (cStr === tStr) {
                     this.$alert(this.dev_type_add_once, '', {
                        confirmButtonText: this.confirm,
                        type: 'error'
                     })
                     return
                  }
               }
            }
            let contcatArr = this.editTriggerDevConfirmArr.concat(tempArr)
            this.editTriggerDevConfirmArr = contcatArr
            this.editTriggerStr = triggerStr
            this.editSaveTriggerDevStr = saveTriggerDevStr // 位置、名称、别名、属性(用于保存)
            this.editSaveTriggerCodePropertyStr = saveTriggerCodePropertyStr // 编码、属性(用于保存)
            this.editSaveTriggerCodeStr = saveTriggerCodeStr // 编码(用于保存)
            this.editSaveTriggerIndexStr = saveTriggerIndexStr // 位数索引(用于保存)
            // 清空选中
            this.triggerRadio = '00' // 单选
            for (var f = 0; f < this.dEditTriggerDevList.length; f++) {
               this.dEditTriggerDevList[f].checked = false // 复选
            }
         },
         // 添加执行设备--编辑场景
         edit_execute_dev_add () {
            let radio = ''
            for (var k = 0; k < this.dEditDeviceAttr.length; k++) {
               if (this.executeRadio === this.dEditDeviceAttr[k].label) {
                  radio = this.dEditDeviceAttr[k].attrName
               }
            }
            let arr = this.dEditExecuteDevList
            let tempArr = []
            let tempObj = {}
            let executeStr = ''
            let saveExecuteDevStr = '' // 位置、名称、别名、属性(用于保存)
            let saveExecuteCodePropertyStr = '' // 编码、属性(用于保存)
            let saveExecuteCodeStr = '' // 编码(用于保存)
            let saveExecuteIndexStr = '' // 位数索引(用于保存)
            let flag = false
            for (var i = 0; i < arr.length; i++) {
               if (arr[i].checked === true) {
                  flag = true
                  tempObj.code = arr[i].devCode
                  tempObj.location = '—>' + arr[i].devLocation
                  tempObj.text = ' : ' + arr[i].devName
                  tempObj.alias = '【' + arr[i].devAlias + '】'
                  tempObj.property = '- ' + radio
                  tempArr.push(tempObj)
                  tempObj = {}
                  executeStr += arr[i].devCode + '—>' + arr[i].devLocation + ' : ' + arr[i].devName + '【' + arr[i].devAlias + '】' + '- ' + radio + '\n'
                  saveExecuteDevStr += arr[i].devLocation + ' : ' + arr[i].devName + '【' + arr[i].devAlias + '】' + ' - ' + radio + ','
                  saveExecuteCodePropertyStr += arr[i].devCode + ' - ' + radio + ','
                  saveExecuteCodeStr += arr[i].devCode + ','
                  saveExecuteIndexStr += arr[i].seatIdx + ','
               }
            }
            // 判断复选框
            if (flag === false) {
               this.$alert(this.sel_execute_device, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 判断单选框
            if (this.executeRadio === '00') {
               this.$alert(this.sel_dev_property, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let confrimArr = this.editExecuteDevConfirmArr
            let tStr = ''
            let cStr = ''
            // 判断是否存在
            for (var s = 0; s < confrimArr.length; s++) {
               cStr = confrimArr[s].code + confrimArr[s].location + confrimArr[s].text + confrimArr[s].alias
               for (var d = 0; d < tempArr.length; d++) {
                  tStr = tempArr[d].code + tempArr[d].location + tempArr[d].text + tempArr[d].alias
                  if (cStr === tStr) {
                     this.$alert(this.dev_type_add_once, '', {
                        confirmButtonText: this.confirm,
                        type: 'error'
                     })
                     return
                  }
               }
            }
            let contcatArr = this.editExecuteDevConfirmArr.concat(tempArr)
            this.editExecuteDevConfirmArr = contcatArr
            this.editExecuteStr = executeStr
            this.editSaveExecuteDevStr = saveExecuteDevStr // 位置、名称、别名、属性(用于保存)
            this.editSaveExecuteCodePropertyStr = saveExecuteCodePropertyStr // 编码、属性(用于保存)
            this.editSaveExecuteCodeStr = saveExecuteCodeStr // 编码(用于保存)
            this.editSaveExecuteIndexStr = saveExecuteIndexStr // 位数索引(用于保存)
            // 清空选中
            this.executeRadio = '00' // 单选
            for (var f = 0; f < this.dEditExecuteDevList.length; f++) {
               this.dEditExecuteDevList[f].checked = false // 复选
            }
         },
         // 删除触发设备--编辑场景
         edit_trigger_add_del (idx) {
            this.editTriggerDevConfirmArr.splice(idx, 1)
         },
         // 删除执行设备--编辑场景
         edit_execute_add_del (idx) {
            this.editExecuteDevConfirmArr.splice(idx, 1)
         },
         // 保存触发设备--编辑场景
         edit_tigger_edit_save () {
            if (this.editTriggerDevConfirmArr.length <= 0) {
               this.$alert(this.confirm_trigger_device, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            this.edit_trigger_add_reset() // 编辑场景--触发设备--添加--确认触发设备及属性--重置
            this.dEditTriggerDevVal = this.editTriggerStr
            this.dTriggerEdit = false
         },
         // 保存执行设备--编辑场景
         edit_execute_edit_save () {
            if (this.editExecuteDevConfirmArr.length <= 0) {
               this.$alert(this.confirm_execute_device, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            this.edit_execute_add_reset() // 编辑场景--执行设备--添加--确认触发设备及属性--重置
            this.dEditExecuteDevVal = this.editExecuteStr
            this.dExecuteEdit = false
         },
         // 复制场景(显示)
         copy (row) {
            // console.log(row)
            this.copySave = false
            // this.dCopyRoomId = row.room_id
            this.dCopySceneId = row.scene_id // 场景ID
            this.hotelValRoom = row.hotel_id
            this.dCopyRoom = true
            let rooUrl = urlObj.getRoomLists
            let rooParam = {
               hotelId: row.hotel_id, // 酒店ID
               roomTypeId: row.room_type_id, // 房型ID
               // floor: this.dFloorVal,
            }
            // console.log(rooUrl, rooParam)
            this.$axios.post(rooUrl, rooParam).then((res) => {
               if (res.success) {
                  // console.log(res)
                  let arr = res.records
                  if (!arr || arr.length <= 0) {
                     this.dCopyRoomArr = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObj.roomId = arr[i].id
                     tempObj.checked = false
                     tempObj.roomNo = arr[i].roomNo
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  // this.dAppRoomList = tempArr
                  // // 房间号(复制场景)
                  // let tempRoomArr = []
                  // for (var k = 0; k < tempArr.length; k++) {
                  //   if (row.room_id !== tempArr[k].roomId) {
                  //     tempRoomArr.push(tempArr[k])
                  //   }
                  // }
                  this.dCopyRoomArr = tempArr
               }
            }).catch(error => {
               this.dCopyRoomArr = []
               console.log(error)
            })
            // this.get_room_type() // 获取房间类型
            // this.get_floor() // 获取楼层
            // this.get_room_list(row.room_id) // 获取房间列表
         },
         // 重置(复制场景)
         copy_reset () {
            this.dRoomTypeVal = ''
            this.dFloorVal = ''
         },
         // 复制场景(保存)
         copy_save (val) {
            // let arr = this.dAppRoomList
            this.copySave = true
            let arr = this.dCopyRoomArr
            let sceneIdStr = ''
            let sceneRoomStr = ''
            for (var i = 0; i < arr.length; i++) {
               if (arr[i].checked === true) {
                  sceneIdStr += arr[i].roomId + ','
                  sceneRoomStr += arr[i].roomNo + ','
               }
            }
            if (sceneIdStr.length <= 0) {
               this.$alert(this.select_copy_room_num, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let copUrl = urlObj.singleSceneClone
            if (val) {
               copUrl = urlObj.sceneDimCopy
            }
            let copParam = {
               // hotelId: this.hotelValRoom, // 酒店id
               sceneId: this.dCopySceneId, // 场景ID
               targetRoomIds: sceneIdStr, // 房间号ID
               targetRoomNos: sceneRoomStr // 房间号
            }
            console.log(copUrl, copParam)
            this.$axios.get(copUrl, copParam).then((res) => {
               console.log(res)
               if (res.success) {
                  let arr = res.records
                  // let successRoomArr = arr.successRoom.split(',')
                  // let failureRoomArr = arr.failRoom.split(',')
                  let successRoomArr = arr.successRoom
                  let failureRoomArr = arr.failRoom
                  let successArr = []
                  let failureArr = []
                  let successObj = {}
                  let failureObj = {}
                  // -成功房间-------------------------------------------------
                  for (var i = 0; i < successRoomArr.length; i++) {
                     successObj.roomNo = successRoomArr[i].roomNo
                     successArr.push(successObj)
                     successObj = {}
                  }
                  this.dCopySuccessRoom = successArr
                  // -失败房间-------------------------------------------------
                  for (var k = 0; k < failureRoomArr.length; k++) {
                     failureObj.reason = failureRoomArr[k].reason
                     failureObj.roomNo = failureRoomArr[k].roomNo
                     failureArr.push(failureObj)
                     failureObj = {}
                  }
                  this.dCopyFailureRoom = failureArr
                  this.$message({
                     showClose: true,
                     message: this.copy_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 应用房间清除
         roomClose (done) {
            this.hotelValRoom = this.hotelOpt[0].value
            this.dRoomTypeVal = ''
            this.dFloorVal = ''
            this.addAllSelect = false // 全选(新建场景)
            done()
         },
         matchingClose (done) {
            this.newType = []
            done()
         },
         // 关闭
         handleClose (done) {
            // alert(111)
            this.saveHotelRoomId = ''
            this.addAllSelect = false // 全选(新建场景)
            this.copyAllSelect = false // 全选(复制场景)
            this.copySave = false
            this.all_select() // 全选(房间列表)
            // this.all_select_copy() // 全选复制场景(房间列表)
            this.add_scene_reset() // 重置(添加场景)
            this.add_room_reset() // 重置(添加应用房间)
            this.copy_reset() // 重置(复制场景)
            this.newModel = []
            this.edit_trigger_add_reset() // 编辑场景--触发设备--添加--确认触发设备及属性--重置
            this.edit_execute_add_reset() // 编辑场景--执行设备--添加--确认触发设备及属性--重置
            done()
         }
      }
   }
</script>
<style scoped lang="scss">
    .m-bottom-15{
        line-height: 17px;
    }
    .font-icon{
        font-size:30px;
        margin-right: 10px;
        color: #409EFF;
        cursor: pointer;
    }
    .cks{
        line-height:28px;
        margin-right: 10px!important;
    }
    .execute_top{
        position: relative;
        top: -21px;
        right: 0;
    }
    .p-title{
        color: #FFF;
        background:#409EFF;
        line-height: 28px;
        text-align: center;
    }
    .device-box{
        border:1px solid #dedede;
        box-sizing: border-box;
        display:inline-block;
        height:400px;
        width:45%;
        overflow:auto;
    }
    .add-btn-box{
        box-sizing: border-box;
        display:inline-block;
        height: 400px;
        width: 88px;
        text-align: center;
        overflow: hidden;
    }
    .el-col > .del-icon{
        color: #F56C6C;
        cursor: pointer;
    }
    .device-box .el-radio{
        margin-right: 10px!important;
    }
    .device-box .el-radio__label{
        padding-left: 5px!important;
    }
    .copy-tit{
        background: #409EFF;
        color: white;
        // background: rgba(222, 222, 222, 0.35);
        // color: #b5b3b3;
        text-align: center;
        line-height: 50px;
        position: relative;
        font-size: 20px;
    }
    .success-room-box, .failure-room-box{
        width: 48%;
        float: left;
    }
    .failure-room-box{
        float: right;
    }
    .examp .examp_box{
        box-sizing: border-box;
        height: auto;
        padding: 0px 15px;
    }
    .examp .examp_bottom{
        position: relative;
        border-bottom: 1px solid #dedede;
    }
    .label_icon i{
        position: absolute;
    }
    .examp_edit{
        position: absolute;
        top: 0;
        right: 15px;
        color: white;
    }
    .examp-box{
        width: 46%;
    }
    .activeNames{
        height: 48px;
        line-height: 48px;
    }
    .newType_bottom{
        padding-left: 10px;
        border-bottom: 1px solid #dedede;
    }
    #mandatory, .label_icon{
        position: relative;
    }
    #mandatory .room-list{
        padding: 0;
    }
    #mandatory .mand_copy{
        border: 1px solid #dedede;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-left: -24px;
        margin-top: -20px;
        text-align: center;
        padding: 4px;
    }
    .room-list .examp_top{
        height: 50%;
    }
    .room-list{
        padding: 15px;
        border: 1px solid #dedede;
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
        height: 300px;
        overflow: auto;
        .box-pad{
            color: #00BFBF;
            border: 1px solid rgba(0, 191, 191, 1);
            background: rgba(0, 191, 191, 0.2);
            padding: 2px;
        }
    }
    .room-list-box{
        max-height: 300px;
        overflow: auto;
    }
    .prompt_info{
        padding: 0 5px;
    }
    .room-list-info{
        display: flex;
        justify-content: flex-start;
        // line-height: 17px;
    }
    .over_info{
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .copy_scene >>> .el-dialog{
        min-height: 450px;
    }
    .pag_bottom {
        position: relative;
    }
    .pag_button {
        position: absolute;
        left: 10px;
        top: -6px;
    }
    .type-box{
        border: 1px solid #dedede;
        margin-top: 10px;
        padding: 10px;
        max-height: 330px;
        /* padding-left: 20px; */
        overflow:auto;
    }
    .type-matching{
        border: 1px solid #dedede;
        margin-top: 10px;
        padding: 10px;
    }
    .chenckbox{
        border: 1px solid #dedede;
        min-height: 270px;
        overflow:auto;
        padding: 10px;
        box-sizing: border-box;
    }
    .lab-box div{
        display: inline-block;
        margin-bottom: 10px;
        padding-left: 10px;
    }
    .lab-boxs div{
        margin-right: 5px;
    }
    .examp_name{
        position: relative;
    }
    .examp_name span{
        position: absolute;
        top: 28px;
        left: 0;
        width: 200px;
        height: 48px;
        line-height: 48px;
        z-index: 9;
    }
    .edit_device .m-bottom-15{
        line-height: 30px;
    }
    .disc-padding{
        padding-right: 30px;
        display: inline-block;
        width: 40%;
    }
    .collapse_li{
        min-height: 200px;
        max-height: 520px;
        overflow: auto;
        position: relative;
    }
    .collapse_li_img{
        position: absolute;
        margin: auto;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        text-align: center;
    }
    .collapse_li_img img{
        margin-top: 6%;
        height: 50px;
        width: 50px;
    }
    .collapse_li span{
        font-size: 14px;
    }
    .dialog-footer .loading_button{
        position: absolute;
        left: 0;
        top: 0;
        border: 0;
        background: #fff;
        color: #409EFF;
        font-size: 32px;
    }
    .loading_button span{
        font-size: 16px;
        color: black;
        vertical-align: middle;
    }
    .floor-bottom{
        /deep/.el-tabs__header{
            margin-bottom: 0;
        }
    }
</style>
